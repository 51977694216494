import React from "react";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import Button from "@mui/material/Button";
import VerticalAlignTopIcon from "@mui/icons-material/VerticalAlignTop";
import { FaDownload } from "react-icons/fa";

const ReportGenerate = ({ excelData, sheetName, fileName }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8";
  const fileExtension = ".xlsx";
  var wscols = [
    { wch: 50 },
    { wch: 40 },
    { wch: 20 },
    { wch: 30 },
    { wch: 30 },
    { wch: 25 },
    { wch: 60 },
    { wch: 60 },
  ];
  const addStyles = (workSheet) => {
    Object.entries(workSheet)
      .filter(([k, v]) => k.charAt(0) === "A")
      .forEach(([k, v]) => {
        if (v.v === "Year") {
          v.s = {
            fill: {
              fgColor: { rgb: "FFFFFF" },
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
            },
            font: {
              bold: false,
            },
          };
        } else {
          v.s = {
            alignment: {
              vertical: "center",
              horizontal: "center",
              wrapText: true,
            },
            width: {
              auto: true,
            },
            height: {
              auto: true,
            },
            font: {
              bold: true,
            },
          };
        }
        if (k === "A1") {
          v.s = {
            fill: {
              fgColor: { rgb: "ffffff" },
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
              wrapText: true,
            },
            width: {
              auto: true,
            },
            font: {
              bold: true,
            },
          };
        }
      });

    Object.entries(workSheet)
      .filter(([k, v]) =>
        [
          "B",
          "C",
          "D",
          "E",
          "F",
          "G",
          "H",
          "I",
          "J",
          "K",
          "L",
          "M",
          "N",
          "O",
          "P",
          "Q",
          "R",
          "S",
        ].includes(k.charAt(0))
      )
      .forEach(([k, v]) => {
        v.s = {
          alignment: {
            vertical: "center",
            horizontal: "center",
            wrapText: true,
          },
          width: {
            auto: true,
          },
          height: {
            auto: true,
          },
        };
        if (
          k === "B1" ||
          k === "C1" ||
          k === "D1" ||
          k === "E1" ||
          k === "F1" ||
          k === "G1" ||
          k === "H1" ||
          k === "I1" ||
          k === "J1" ||
          k === "K1" ||
          k === "L1" ||
          k === "M1" ||
          k === "N1" ||
          k === "O1" ||
          k === "P1" ||
          k === "Q1" ||
          k === "R1" ||
          k === "S1"
        ) {
          v.s = {
            fill: {
              fgColor: { rgb: "ffffff" },
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
              wrapText: true,
            },
            width: {
              auto: true,
            },
            font: {
              bold: true,
            },
          };
        }
      });
  };

  const excelDataLength = excelData.length;
  const rowHeight = Array(excelDataLength)
    .fill()
    .map((item) => ({ hpt: 30 }));

  const exportTOExcel = async () => {

    // const transformedData = transformData(excelData);
    const ws = XLSX.utils.json_to_sheet(excelData);

    const range = ws["!ref"];
    ws["!autofilter"] = { ref: range };
    addStyles(ws);
    ws["!cols"] = wscols;
    ws["!rows"] = rowHeight;
    const wb = {
      Sheets: { [`${sheetName}`]: ws },
      SheetNames: [`${sheetName}`],
    };
    const exelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([exelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <div style={{ background: "#eee", borderRadius: "4px" }}>
      <Button
        style={{
          backgroundColor: "#eee",
          fontSize: "14px",
          color: "#fff",
          padding: "5px",
          borderRadius: "6px",
          width: "40px",
          height: "45px",
        }}
        onClick={(e) => exportTOExcel(fileName)}
        className="export-button"
        title="Export in Excel"
      >
        <FaDownload style={{ color: "#bb1ccc", fontSize: "20px" }} />
      </Button>
    </div>
  );
};

export default ReportGenerate;
