import React, { useEffect, useState } from "react";
import MessagePopup from "./MessagePopup";
import "./AnswerDisplay.scss";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import ReactMarkdown from "react-markdown";
import PlotlyGraph from "../layout/PlotlyGrapgh";
import Feedback from "../feedback/Feedback";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import QueryOptions from "./ResponseQueryOptions";
import { useMediaQuery } from "react-responsive";
import $ from "jquery";
import AssistantIcon from "@mui/icons-material/Assistant";
import botImg from "../../app/assets/videos/Loading-gif.gif";
import axios from "axios";
import HighlightText from "./HighlightText";
const loadingMessages = [
  { text: "Understanding the questions...", delay: 2000 },
  { text: "Generating SQL...", delay: 2000 },
  { text: "Synthesizing the insights...", delay: 2000 },
  { text: "Preparing reports...", delay: 2000 },
];
const AnswerDisplay = ({
  item,
  setIsLoading,
  isLoading,
  index,
  printPDF,
  responseNumber,
  responseId,
  handlePin,
  pinError,
  isPinned,
  pinLoading,
  childIndex,
}) => {
  const [open, setOpen] = useState(false);
  const [currentMessage, setCurrentMessage] = useState(item);
  const [openOptions, setOpenOptions] = useState(false);
  const [followUp, setFollowUp] = useState([]);
  const [llmLoading, setLlmLoading] = useState(false);
  const [llmmError, setLlmError] = useState(false);
  const [loadingStep, setLoadingStep] = useState(0);

  const handleExpandClick = (item) => {
    // setCurrentMessage(item);
    setOpen(true);
  };
  function trimString(str, maxLength) {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + "...";
    }
    return str + " ...";
  }
  const getBackgroundColor = (score) => {
    const parsedScore = parseFloat(score);
    if (isNaN(parsedScore)) {
      return "red";
    }
    return parsedScore > 0.75 ? "green" : parsedScore > 0.5 ? "#FFC000" : "red";
  };
  const backgroundColor = getBackgroundColor(currentMessage.confidence_score);
  const isTab = useMediaQuery({ query: "(max-width: 768px)" });

  const handleLLMResponse = async () => {
    setLlmLoading(true);
    setLlmError(false);
    const dataItem = {
      Response_ID: responseId,
      Section_ID: currentMessage.child_question_id + "",
    };

    const baseurl = `${process.env.REACT_APP_PYTHON_BACKEND_URL}/ask_llmeval`;
    try {
      const response = await axios.post(baseurl, dataItem);
      const data = response.data;

      if (response.status === 200) {
        const botResponse = data.response;
        setCurrentMessage(botResponse?.content[0]);
        setLlmLoading(false);
        setLlmError(false);
      } else {
        setLlmLoading(false);
        setLlmError(true);
      }
    } catch (err) {
      setLlmLoading(false);
      setLlmError(true);
      console.log("error occurred", err);
    }
  };

  useEffect(() => {
    $(".summary-text").each(function () {
      // Select all <p> tags inside current .summary-text element except the first one
      // $(this).find('p:not(:first-child)').remove();
      const paragraphs = $(this).find("p");
      $(this).children(":not(p, pre)").remove();
      // Check number of <p> tags
      if (paragraphs.length > 1) {
        // Remove all <p> tags except the first one
        paragraphs.slice(1).remove();
      }
    });
  }, []);
  useEffect(() => {
    if (llmLoading && loadingStep < loadingMessages.length) {
      const timer = setTimeout(() => {
        setLoadingStep((prevState) => prevState + 1);
      }, loadingMessages[loadingStep].delay);

      return () => clearTimeout(timer);
    }
  }, [llmLoading, loadingStep]);


  return (
    <div
      className={`response-card ${llmLoading ? "llm-container" : ""} ${
        childIndex === responseNumber - 1 && llmLoading ? "llm-full-height" : ""
      }`}
    >
      {llmLoading && (
        <div className="loading-cont">
          <img width={120} src={botImg} alt="aadi-loading" />
          <p className="loading-text">
            {loadingStep < loadingMessages.length
              ? loadingMessages[loadingStep].text
              : "Preparing reports..."}
          </p>
        </div>
      )}
      {!llmLoading && !llmmError && (
        <>
          {" "}
          <div className="first-info-cont mobile-header-layout">
            <div
              style={{
                display: "flex",
                justifyContent:
                  currentMessage.header && isTab
                    ? "space-between"
                    : "space-between",
                width: "100%",
                alignContent: "center",
                alignItems: "center",
                paddingBottom: "10px",
              }}
            >
              <div
                className="mobileLayout-width"
                style={{ width: isTab ? "60%" : "80%" }}
              >
                <span className="response-header">
                  {currentMessage.header ? currentMessage.header : ""}
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "20px",
                  justifyContent: "flex-end",
                  alignContent: "center",
                  alignItems: "center",
                  justifyItems: "center",
                }}
                className="mobile-score-layout"
              >
                {currentMessage.confidence_score !== undefined &&
                  currentMessage.confidence_score !== "NA" && (
                    <div
                      style={{
                        backgroundColor: backgroundColor,
                        color:
                          parseFloat(currentMessage.confidence_score).toFixed(
                            2
                          ) > 0.75
                            ? "white"
                            : parseFloat(
                                currentMessage.confidence_score
                              ).toFixed(2) > 0.5
                            ? "black"
                            : "white",
                        padding: "7px",
                        borderRadius: "6px",
                        // position: "absolute",
                        // top: "10px",
                        // right: "45px",
                        fontWeight: "600",
                      }}
                    >
                      {parseFloat(currentMessage.confidence_score).toFixed(2)}
                    </div>
                  )}
                <div
                  className={`three-dots-icon ${
                    openOptions ? "option-active" : ""
                  }`}
                  onClick={() => {
                    setOpenOptions((prevState) => true);
                  }}
                >
                  <MoreVertIcon />
                </div>
              </div>
            </div>
            {/* {item.status && (
        <div
          className="expand-icon"
          onClick={() => handleExpandClick(item)} // Pass the clicked item's text to the handler
        >
          <OpenInFullIcon />
        </div>
      )} */}
          </div>
          {/* <ReactMarkdown className="summary-text">
            {currentMessage.summary.toLowerCase() === "na"
              ? currentMessage.message
              : responseNumber > 1
              ? trimString(currentMessage.summary, 600)
              : currentMessage.summary}
          </ReactMarkdown> */}

          <HighlightText summary={currentMessage.summary.toLowerCase() === "na"
              ? currentMessage.message
              : responseNumber > 1
              ? trimString(currentMessage.summary, 600)
              : currentMessage.summary} />
          {currentMessage.summary &&
            currentMessage.summary.toLowerCase() !== "na" && (
              <p
                className="read-more-btn"
                onClick={() => handleExpandClick(currentMessage)}
              >
                Read More
              </p>
            )}
          {currentMessage.figure && (
            <div
              style={{
                position: "relative",
                width: "100%",
                height: "auto",
                marginTop: "16px",
              }}
              className="plotly-cont"
            >
              <PlotlyGraph
                graph={currentMessage.figure}
                childId={currentMessage.child_question_id}
                version={currentMessage.version ? currentMessage.version : 1}
                AnswerDisplay="AnswerDisplay"
                messageID={responseId}
                tableCoumn={currentMessage.dataframe}
              />
            </div>
          )}
          <div className="HideMoreInPrints response-footer-cont">
            <Feedback
              feedbackStatus={
                currentMessage.feedback ? currentMessage.feedback : ""
              }
              messageID={responseId}
              index={index}
              child_response_id={index}
            />
            {currentMessage?.from_cache && (
              <div className="response-generate" title="Generate via LLM">
                Generated from Cache{" "}
                <AssistantIcon onClick={() => handleLLMResponse()} />{" "}
              </div>
            )}
          </div>
        </>
      )}
      <MessagePopup
        open={open}
        setOpen={setOpen}
        item={currentMessage}
        index={index}
        printPDF={printPDF}
        messageID={responseId}
        isPinned={isPinned}
        pinError={pinError}
        handlePin={handlePin}
        version={currentMessage.version ? currentMessage.version : 1}
        pinLoading={pinLoading}
        setCurrentMessage={setCurrentMessage}
        currentMessage={currentMessage}
        followUp={followUp}
        setFollowUp={setFollowUp}
      />{" "}
      {/* Pass the current message to the popup */}
      {openOptions && (
        <QueryOptions
          handleExpandClick={handleExpandClick}
          setOpenOptions={setOpenOptions}
          isPinned={isPinned}
          pinError={pinError}
          handlePin={handlePin}
          item={currentMessage}
          messageID={responseId}
          pinLoading={pinLoading}
        />
      )}
    </div>
  );
};

export default AnswerDisplay;
