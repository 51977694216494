import React, { useEffect, useState, useRef } from "react";
import { styled, useTheme, alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import {
  FaSignInAlt,
  FaChartBar,
  FaRegCommentDots,
  FaChartLine,
  FaCog,
  FaHistory,
  FaPlus,
  FaMinus,
  FaDatabase,
} from "react-icons/fa";
import Topnavbar from "./Topnavbar";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import logo from "../assets/img/Aays.png";
import logo2 from "../assets/img/AadiLogo.png";
import History from "../../components/faqs/History";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import "./nav.scss";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { redirect } from "react-router";
import PushPinIcon from "@mui/icons-material/PushPin";
import { useMediaQuery } from "react-responsive";
import { Drawer as MuiDrawer } from "@mui/material/";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";

const SideNavbar = ({
  dataItems,
  setCompany,
  setisDefaultPage,
  setHistoySections,
  HistoySections,
  nodataImage,
  isLoading,
  fetchHistoryDataItem,
  setSuccessHistoryloading,
  newChatStart,
  historyFlag,
  setHistoryFlag,
  isDefaultPage,
  page,
  historyDataFlag,
  setHistoryDataFlag,
}) => {
  const [open, setOpen] = useState(false);
  const [openDialog, setopenDialog] = useState(false);
  const [isexpand, setisexpand] = useState(() => {
    const storedValue = localStorage.getItem("historyCollapse");
    return storedValue === "true" ? true : false;
  });

  const [bgColor, sebgColor] = useState("black");
  const drawerWidth = 300;

  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState();
  const history = useHistory();

  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  // const [lastPathname, setLastPathname] = useState("");
  // const [currentPathname, setCurrentPathname] = useState("");
  const isTab = useMediaQuery({ query: "(max-width: 768px)" });

  const toggleDrawer = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      setUserInfo(null);
    } else {
      setUserInfo(authState.idToken.claims);
      const dataItem = authState.idToken.claims;
      const email = dataItem.email;
      localStorage.setItem("userinfo", email);
    }
  }, [authState, oktaAuth]);

  const logout = async () => {
    await oktaAuth.signOut();
  };

  const { pathname } = useLocation();

  const MenuItems = [
    {
      menu: "Decision Intelligence",
      route: "/",
      icon: <FaRegCommentDots className="colorCss" fontSize={20} />,
    },
    {
      menu: "KnowledgeGraph",
      route: "/KnowledgeGraph",
      icon: <FaChartLine className="colorCss" fontSize={20} />,
    },

    {
      menu: "Dashboard",
      route: "/Dashboard",
      icon: (
        <SpaceDashboardIcon className="colorCss" sx={{ fontSize: "20px" }} />
      ),
    },
    {
      menu: "Workbench",
      route: "/Workbench",
      icon: (
        <HomeRepairServiceIcon className="colorCss" sx={{ fontSize: "20px" }} />
      ),
    },
    {
      menu: "Data Dictionary",
      route: "/Data-Dictionary",
      icon: <FaDatabase className="colorCss" fontSize={20} />,
    },
  ];

  const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    background: `${bgColor}`,
    color: "white",
    overflowX: "hidden",
    borderRight: "1px dotted rgba(86,88,105, 1) !important",
    "&& .Mui-selected": {
      outline: "0px",
      border: "0px",
      cursor: "pointer",
      userSelect: "none",
      verticalAlign: "middle",
      appearance: "none",
      display: "flex",
      flexGrow: "1",
      justifyContent: "flex-start",
      alignItems: "center",
      textDecoration: "none",
      boxSizing: "border-box",
      textAlign: "left",
      transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      position: "relative",
      textTransform: "capitalize",
      color: "rgb(32, 101, 209);",
      borderRadius: "8px !important",
      backgroundColor: "#0000a0",
      fontWeight: "700 !important",
    },
    "&& .logoutLayout": {
      left: "20px !important",
      position: "Absolute",
      bottom: "20px",
    },
  });

  const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: `${bgColor}`,
    color: "white",
    overflowX: "hidden",
    borderRight: "1px dotted rgba(86,88,105, 1) !important",
    padding: "0px",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(8)} + 20px)`,
    },
    "&& .Mui-selected": {
      borderRadius: "8px !important",
    },
    "&& .logoutLayout": {
      left: "8px !important",
      position: "Absolute",
      bottom: "20px",
    },
    "&& .navHistory": {
      display: "none",
    },
  });

  const openbox = (theme) => ({
    left: "286px !important",
  });

  const closebox = (theme) => ({
    left: "72px !important",
  });

  const listTogglebox = (theme) => ({
    padding: "0 !important",
    paddingTop: "2rem !important",
    padding: "1rem .5rem 0rem !important",
    borderRadius: "20px",
  });

  const listTogglebox1 = (theme) => ({
    display: "none !important",
  });

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "noWrap",
    boxSizing: "border-box",

    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
      "& .navButtonToggle": openbox(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
      "& .navButtonToggle": closebox(theme),
      "& .listToggle": listTogglebox(theme),
      "& .listToggle1": listTogglebox1(theme),
    }),
  }));

  useEffect(() => {
    if (isDefaultPage) {
      setOpen(false);
    } else if (!isDefaultPage && isTab) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [isDefaultPage]);

  const theme = useTheme();

  useEffect(() => {
    if (isTab) {
      setOpen(false);
    }
  }, [isTab]);

  useEffect(() => {
    if (isTab) {
      setOpen(false);
    }
  }, [isTab, pathname]);

  const handleChange = () => {
    setisDefaultPage(true);
  };
  useEffect(() => {
    if (!isTab) {
      if (isDefaultPage) {
        setOpen(false);
      } else {
        setOpen(true);
      }
    }
  }, [isTab, isDefaultPage]);

  return (
    <div>
      {isTab ? (
        <div id="sidenavBarnew">
          <Box
            sx={{
              // display: "flex",
              // overflow: "hidden",
              marginLeft: isTab ? `${-drawerWidth}px` : 0,
            }}
          >
            <Topnavbar
              setOpen={setOpen}
              setCompany={setCompany}
              dataItems={dataItems}
              open={open}
              setHistoySections={setHistoySections}
              HistoySections={HistoySections}
              setopenDialog={setopenDialog}
              setisDefaultPage={setisDefaultPage}
              page={page}
              toggleDrawer={toggleDrawer}
            />
            <MuiDrawer
              variant="persistent"
              noWrap
              open={open}
              anchor="left"
              onClose={toggleDrawer}
              ModalProps={{
                keepMounted: true,
              }}
              PaperProps={{
                sx: {
                  width: drawerWidth,
                  backgroundColor: "black",
                  flexShrink: 0,
                  display: { xs: "block", sm: "none" },
                  "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: drawerWidth,
                  },
                },
              }}
            >
              <div
                style={{
                  position: "relative",
                  marginTop: "1.2rem",
                  justifyItems: "start",
                  display: "flex",
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                  // height: "100px",
                  justifyContent: "space-between",
                  alignContent: "center",
                  alignItems: "center",
                  background: "black",
                }}
              >
                <div style={{ color: "white" }} onClick={() => setOpen(!open)}>
                  <MenuOpenIcon sx={{ fontSize: "25px", cursor: "pointer" }} />
                </div>

                <div
                  style={{ cursor: "pointer", color: "white" }}
                  onClick={() => {
                    if (page && page === "Home") {
                      newChatStart();
                    } else {
                      history.push("/");
                    }
                  }}
                >
                  <AppRegistrationIcon />
                </div>
              </div>
              <List
                className="listToggle"
                style={{
                  padding: "1.3rem",
                  display: "grid",
                  gridGap: "1rem",
                  paddingTop: "3rem",
                  alignContent: "baseline",
                  alignItems: "baseline",
                  backgroundColor: "black",
                }}
              >
                {MenuItems.map((menuItem, index) => (
                  <Link
                    key={index}
                    style={{ color: "white" }}
                    to={`${menuItem.route}`}
                  >
                    <ListItem
                      className="itemsmenulist colorCss"
                      selected={menuItem.route === pathname}
                      disablePadding
                      sx={{ display: "block" }}
                      title={menuItem.menu}
                    >
                      <ListItemButton
                        sx={{
                          minHeight: 53,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                        }}
                        onClick={() => {
                          if (page === "Home") {
                            handleChange();
                          } else {
                            setOpen(false);
                            // return;
                          }
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                          }}
                          className="colorCss"
                        >
                          {menuItem.icon}
                        </ListItemIcon>
                        <ListItemText
                          className="listToggle1"
                          primary={menuItem.menu}
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    </ListItem>
                  </Link>
                ))}

                <div>
                  <ListItem
                    className="itemsmenulist itemsmenulist2"
                    title="History"
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 53,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <FaHistory className="colorCss" fontSize={20} />
                      </ListItemIcon>
                      <ListItemText
                        className="listToggle1 colorCss"
                        primary="Recent History"
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                      <div>
                        {isexpand ? (
                          <div
                            onClick={() => {
                              setisexpand(!isexpand);
                              localStorage.setItem("historyCollapse", false);
                            }}
                          >
                            <FaMinus
                              className="listToggle1 colorCss"
                              fontSize={14}
                            />
                          </div>
                        ) : (
                          <div
                            onClick={() => {
                              localStorage.setItem("historyCollapse", true);
                              setisexpand(!isexpand);
                            }}
                          >
                            <FaPlus
                              className="listToggle1 colorCss"
                              fontSize={14}
                            />
                          </div>
                        )}
                      </div>
                    </ListItemButton>
                  </ListItem>
                </div>

                {isexpand && (
                  <div className="navHistory">
                    <History
                      nodataImage={nodataImage}
                      isLoading={isLoading}
                      fetchHistoryDataItem={fetchHistoryDataItem}
                      setSuccessHistoryloading={setSuccessHistoryloading}
                      newChatStart={newChatStart}
                      historyFlag={historyFlag}
                      setHistoryFlag={setHistoryFlag}
                      historyDataFlag={historyDataFlag}
                      setHistoryDataFlag={setHistoryDataFlag}
                    />
                  </div>
                )}
              </List>
            </MuiDrawer>
            <Dialog
              open={openDialog}
              onClose={() => setopenDialog(!openDialog)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              id="logoutPopup"
              // className="logout"
            >
              <DialogTitle id="alert-dialog-title">
                {"Are you sure you want to logout ?"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Please confirm your decision.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setopenDialog(!openDialog)}>No</Button>
                <Button onClick={logout}>Yes</Button>
              </DialogActions>
            </Dialog>
          </Box>
        </div>
      ) : (
        <div id="sidenavBarnew">
          <Box sx={{ display: "flex" }}>
            <Topnavbar
              setOpen={setOpen}
              setCompany={setCompany}
              dataItems={dataItems}
              open={open}
              setHistoySections={setHistoySections}
              HistoySections={HistoySections}
              setopenDialog={setopenDialog}
              setisDefaultPage={setisDefaultPage}
              page={page}
            />
            <Drawer
              className="sidenavBarstyle"
              variant="permanent"
              open={open}
              // sx={{
              //     display: { xs: 'none', sm: 'block' },
              //   }}
            >
              {open && (
                <>
                  <div
                    style={{
                      position: "relative",
                      marginTop: "1.2rem",
                      justifyItems: "start",
                      display: "flex",
                      paddingLeft: "1rem",
                      paddingRight: "1rem",
                      // height: "100px",
                      justifyContent: "space-between",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div onClick={() => setOpen(!open)}>
                      <MenuOpenIcon
                        sx={{ fontSize: "30px", cursor: "pointer" }}
                      />
                    </div>

                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (page && (page === "Home" || page === "Workbench")) {
                          newChatStart();
                        } else {
                          history.push("/");
                        }
                      }}
                    >
                      <AppRegistrationIcon />
                    </div>
                  </div>
                </>
              )}

              {!open && (
                <>
                  <div
                    style={{
                      position: "relative",
                      marginTop: "0px",
                      height: "auto",
                    }}
                  >
                    <div
                      style={{
                        paddingTop: "1.5rem",
                        marginTop: "0px",
                        paddingBottom: "1rem",
                        display: "grid",
                        justifyContent: "center",
                        justifyItems: "center",
                      }}
                    >
                      <div onClick={() => setOpen(!open)}>
                        <MenuOpenIcon
                          sx={{
                            fontSize: "30px",
                            cursor: "pointer",
                            transform: "rotate(180deg)",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}

              <List
                className="listToggle"
                style={{
                  padding: "1.3rem",
                  display: "grid",
                  gridGap: "1rem",
                  paddingTop: "3rem",
                  alignContent: "baseline",
                  alignItems: "baseline",
                }}
              >
                {MenuItems.map((menuItem, index) => (
                  <Link
                    key={index}
                    style={{ color: "white" }}
                    to={`${menuItem.route}`}
                  >
                    <ListItem
                      className="itemsmenulist colorCss"
                      selected={menuItem.route === pathname}
                      disablePadding
                      sx={{ display: "block" }}
                      title={menuItem.menu}
                    >
                      <ListItemButton
                        sx={{
                          minHeight: 53,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                        }}
                        onClick={() => {
                          if (page === "Home") {
                            handleChange();
                          } else {
                            return;
                          }
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                          }}
                          className="colorCss"
                        >
                          {menuItem.icon}
                        </ListItemIcon>
                        <ListItemText
                          className="listToggle1"
                          primary={menuItem.menu}
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    </ListItem>
                  </Link>
                ))}

                <div>
                  <ListItem
                    className="itemsmenulist itemsmenulist2"
                    title="History"
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 53,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <FaHistory className="colorCss" fontSize={20} />
                      </ListItemIcon>
                      <ListItemText
                        className="listToggle1 colorCss"
                        primary="Recent History"
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                      <div>
                        {isexpand ? (
                          <div
                            onClick={() => {
                              setisexpand(!isexpand);
                              localStorage.setItem("historyCollapse", false);
                            }}
                          >
                            <FaMinus
                              className="listToggle1 colorCss"
                              fontSize={14}
                            />
                          </div>
                        ) : (
                          <div
                            onClick={() => {
                              setisexpand(!isexpand);
                              localStorage.setItem("historyCollapse", true);
                            }}
                          >
                            <FaPlus
                              className="listToggle1 colorCss"
                              fontSize={14}
                            />
                          </div>
                        )}
                      </div>
                    </ListItemButton>
                  </ListItem>
                </div>

                {isexpand && (
                  <div className="navHistory">
                    <History
                      nodataImage={nodataImage}
                      isLoading={isLoading}
                      fetchHistoryDataItem={fetchHistoryDataItem}
                      setSuccessHistoryloading={setSuccessHistoryloading}
                      newChatStart={newChatStart}
                      historyFlag={historyFlag}
                      setHistoryFlag={setHistoryFlag}
                    />
                  </div>
                )}
              </List>
            </Drawer>

            <Dialog
              open={openDialog}
              onClose={() => setopenDialog(!openDialog)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              id="logoutPopup"
              // className="logout"
            >
              <DialogTitle id="alert-dialog-title">
                {"Are you sure you want to logout ?"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Please confirm your decision.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setopenDialog(!openDialog)}>No</Button>
                <Button onClick={logout}>Yes</Button>
              </DialogActions>
            </Dialog>
          </Box>
        </div>
      )}
    </div>
  );
};

export default SideNavbar;
