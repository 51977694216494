import axios from "axios";
import React, { useState } from "react";
import "./EditDataDict.scss";

function InputField({ label, name, value, onChange }) {
  return (
    <div style={{ display: "grid", gap: "12px", padding: "0rem" }}>
      <div style={{ fontWeight: "600", textTransform: "capitalize" }}>
        {label}
      </div>
      <input
        type="text"
        value={value}
        name={name}
        className="input_field_reportSummary"
        placeholder={label}
        onChange={onChange}
      />
    </div>
  );
}

function EditDataDirectory({
  editReportSummaryData,
  setOpenModal,
  onFetchData,
  onDataChange,
}) {
  const [formData, setFormData] = useState(editReportSummaryData);
  const [loading, setLoading] = useState(false);
  const [error, seterror] = useState(false);
  // const passtoken = JSON.parse(localStorage.getItem("okta-token-storage"))[
  //   "accessToken"
  // ]["accessToken"];
  // console.log("Data for Editing: ", formData);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    setLoading(true);

    // try {
    //   const response = await axios.post(
    //     `${process.env.REACT_APP_BASE_URL}/ai/updateDataDictionary`,
    //     formData,
    //     {
    //       headers: {
    //         "oidc-token": passtoken,
    //         Accept: "application/json",
    //       },
    //     }
    //   );
    //   console.log("Response:", response.data);
    //   setIsloading(false);
    //   setOpenModal(false);
    //   onFetchData();

    //   // Handle successful response (e.g., show a success message or redirect)
    // } catch (error) {
    //   console.error("Error:", error);
    //   // Handle error (e.g., show an error message)
    // }
    setTimeout(() => {
      onDataChange(formData);
      setLoading(false);
      setOpenModal(false);
    }, 2000);
  };

  return (
    <div className="main-container edit-dict-cont">
      <div
        style={{
          padding: "1rem",
          backgroundColor: "#bb1ccc",
          color: "#fff",
          fontSize: "20px",
          fontWeight: "normal",
        }}
        className="heading"
      >
        Edit Report Data
      </div>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          gridAutoRows: "minmax(75px, auto)",
          gap: "12px",
          padding: "12px",
        }}
        className="input-container"
      >
        <InputField
          label="Description"
          value={formData.Description}
          name="Description"
          onChange={handleInputChange}
        />
        <InputField
          label="BRAND FLAG"
          value={formData.BRAND_FLAG}
          name="BRAND_FLAG"
          onChange={handleInputChange}
        />
        <InputField
          label="Portion Size"
          value={formData.portionsize}
          name="portionsize"
          onChange={handleInputChange}
        />
        <InputField
          label="Regional Product Category"
          value={formData["Regional product category"]}
          name="Regional product category"
          onChange={handleInputChange}
        />
        <InputField
          label="H&N Framework"
          value={formData["H&N Framework"]}
          name="H&N Framework"
          onChange={handleInputChange}
        />
        <InputField
          label="Energy KCAL"
          value={formData["ENERGY KCAL"]}
          name="ENERGY KCAL"
          onChange={handleInputChange}
        />
        <InputField
          label="Fruits Content per 100g"
          value={formData["Fruits Content per 100g"]}
          name="Fruits Content per 100g"
          onChange={handleInputChange}
        />
        <InputField
          label="Sugars Added per 100g"
          value={formData["Sugars Added_per_100g"]}
          name="Sugars Added_per_100g"
          onChange={handleInputChange}
        />
        <InputField
          label="Vegetables Content per 100g"
          value={formData["Vegetables_Content per 100g"]}
          name="Vegetables_Content per 100g"
          onChange={handleInputChange}
        />
        <InputField
          label="Wholegrains Content per 100g"
          value={formData["Wholegrains_Content per 100g"]}
          name="Wholegrains_Content per 100g"
          onChange={handleInputChange}
        />
        <InputField
          label="Carbohydrate per 100g"
          value={formData["Carbohydrate_per 100g"]}
          name="Carbohydrate_per 100g"
          onChange={handleInputChange}
        />
        <InputField
          label="Fat Saturates per 100g"
          value={formData["Fat_Saturates_per 100g"]}
          name="Fat_Saturates_per 100g"
          onChange={handleInputChange}
        />
        <InputField
          label="Fat per 100g"
          value={formData["Fat_per 100g"]}
          name="Fat_per 100g"
          onChange={handleInputChange}
        />
        <InputField
          label="Fibre Dietary Total AOAC per 100g"
          value={formData["Fibre_Dietary_Total_AOAC per 100g"]}
          name="Fibre_Dietary_Total_AOAC per 100g"
          onChange={handleInputChange}
        />
        <InputField
          label="Protein per 100g"
          value={formData["Protein per 100g"]}
          name="Protein per 100g"
          onChange={handleInputChange}
        />
        <InputField
          label="Sodium per 100g"
          value={formData["Sodium per 100g"]}
          name="Sodium per 100g"
          onChange={handleInputChange}
        />
        <InputField
          label="Sugars(Mono&Di-saccharides) per 100g"
          value={formData["Sugars_(Mono&Di-saccharides)_per 100g"]}
          name="Sugars_(Mono&Di-saccharides)_per 100g"
          onChange={handleInputChange}
        />
        <InputField
          label="Trans Fatty Acids per 100g"
          value={formData["Trans_Fatty_Acids_per 100g"]}
          name="Trans_Fatty_Acids_per 100g"
          onChange={handleInputChange}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          padding: "1rem",
          gap: "16px",
        }}
      >
        <button
          type="button"
          className="btn secondary-btn"
          disabled={loading}
          onClick={() => {
            setOpenModal(false);
          }}
        >
          Close
        </button>
        <button
          type="button"
          className="btn primary-btn"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? (
            <div
              style={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span style={{ paddingLeft: "10px" }}>Updating...</span>
            </div>
          ) : (
            "Update Report"
          )}
        </button>
      </div>
    </div>
  );
}

export default EditDataDirectory;
