import React, { useState, useRef, useEffect } from "react";
import MainLayout from "../../components/layout/MainLayout";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import EditIcon from "@mui/icons-material/Edit";
// import { tableData, tableHeader } from "../pages/DataDirectory/TableData";
import axios from "axios";
import ReportGenerate from "../../components/Reusable/ReportGenerate";
import ReactPaginate from "react-paginate";
import $ from "jquery";
import Button from "@mui/material/Button";
import ImportData from "./Import/ImportData";
// import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Skeleton } from "@mui/material";
import EditDataDirectory from "./EditDataDict";
import Modal from "@mui/material/Modal";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteModel from "./DeleteModel";
import { Alert } from "@mui/material";
import HomeData from "./HomeData";
import "./DataDictionary.scss";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(2, 0),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

function Row(props) {
  const { row, index, fetchData, onEditData, onDeleteData } = props;
  const [open, setOpen] = React.useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [deleteModel, setDeleteModel] = useState(false);
  const [deleteRowID, setdeleteRowID] = useState("");

  const fetchDataHandler = () => {
    fetchData();
  };

  const dataChangeHandler = (data) => {
    onEditData(data);
  };

  const deleteHandler = (ID) => {
    onDeleteData(ID);
  };

  return (
    <React.Fragment>
      <TableRow
        id={`id_${index}`}
        className={index % 2 === 0 ? "tableRowEven" : "tableRowOdd"}
        sx={{ "& > *": { borderBottom: "unset" } }}
      >
        <TableCell style={{ width: "15%" }} align="left">
          {row.kpiName}
        </TableCell>
        <TableCell align="left">{row.function}</TableCell>
        <TableCell align="left">{row.businessProcess}</TableCell>
        <TableCell align="left">{row.formula}</TableCell>
        <TableCell style={{ width: "15%" }} align="left">
          {row?.kpiOwner}
        </TableCell>
        <TableCell align="left">{row.synonyms}</TableCell>
        <TableCell>
          <div
            style={{
              display: "flex",
              gap: "10px",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? (
                <KeyboardArrowUpIcon
                  sx={{ fontSize: "28px", color: "#bb1ccc" }}
                />
              ) : (
                <KeyboardArrowDownIcon
                  sx={{ fontSize: "28px", color: "#bb1ccc" }}
                />
              )}
            </IconButton>
            {/* {localStorage.getItem("role") === "Admin" && (
              <>
                <IconButton aria-label="expand row" size="small">
                  <EditIcon
                    sx={{ fontSize: "20px !important", color: "#19738d" }}
                    onClick={() => {
                      setOpenModal(true);
                      setEditData(row);
                    }}
                  />
                </IconButton>

                <IconButton aria-label="expand row" size="small">
                  <DeleteIcon
                    sx={{ fontSize: "20px", color: "#ee4b2b" }}
                    onClick={() => {
                      setDeleteModel(true);
                      setdeleteRowID(row.id);
                    }}
                  />
                </IconButton>
              </>
            )} */}
            <>
              {/* <IconButton aria-label="expand row" size="small">
                <EditIcon
                  sx={{ fontSize: "20px !important", color: "#bb1ccc" }}
                  onClick={() => {
                    setOpenModal(true);
                    setEditData(row);
                  }}
                />
              </IconButton> */}

              {/* <IconButton aria-label="expand row" size="small">
                <DeleteIcon
                  sx={{ fontSize: "20px", color: "#ee4b2b" }}
                  onClick={() => {
                    setDeleteModel(true);
                    setdeleteRowID(row.id);
                  }}
                />
              </IconButton> */}
            </>
          </div>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            border: "none",
            background: "#e9ecef",
          }}
          colSpan={7}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography
                variant="div"
                style={{
                  fontSize: "22px",
                  fontWeight: "600",
                  paddingTop: "10px",
                  textDecoration: "underline",
                  color: "#bb1ccc",
                }}
                gutterBottom
                component="div"
              >
                Details Overview
              </Typography>

              <div
                style={{
                  display: "grid",
                  gap: "20px",
                  gridTemplateColumns: "repeat(3, 1fr)",
                  width: "100%",
                }}
              >
                {Object.entries(row).map(
                  ([key, value]) =>
                    key !== "id" &&
                    key !== "createdAt" && key !== "addedBy" &&  (
                      <div style={{ display: "grid", gap: "2px" }} key={key}>
                        <span
                          style={{
                            textTransform: "uppercase",
                            color: "#212121",
                            fontSize: "14px",
                          }}
                        >
                          <strong>{key}:</strong>
                        </span>
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "600",
                            color: "#bb1ccc",
                          }}
                        >
                          {value || "N/A"}
                        </span>
                      </div>
                    )
                )}
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Modal
        id="DataDirectorymodal-box"
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <div className="modal-box">
          <EditDataDirectory
            setOpenModal={setOpenModal}
            onFetchData={fetchDataHandler}
            editReportSummaryData={row}
            onDataChange={dataChangeHandler}
          />
        </div>
      </Modal>

      <Modal open={deleteModel} onClose={() => setDeleteModel(false)}>
        <div style={{ maxWidth: "400px", padding: "0" }} className="modal-box">
          <DeleteModel
            onFetchData={fetchDataHandler}
            setDeleteModel={setDeleteModel}
            rowID={row.GRD}
            onDataChange={deleteHandler}
          />
        </div>
      </Modal>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      })
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

const DataDirectoryTable = () => {
  const [tableHeaderinfo, setTableHeaderinfo] = useState([
    "KPI Name",
    "Function",
    "Business Process",
    "If Yes, Formula",
    "KPI Owner",
    "Synonyms",
    "Actions",
  ]);
  //   const [tableDatainfo, settableDatainfo] = useState(tableHeader);
  const [dataDict, setDataDict] = useState(HomeData);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPagelimit, setPageLimit] = useState(10);
  const [query, setQuery] = useState("");
  const [filterFunctionBusiness, setfilterFunctionBusiness] = useState("");
  const [filterGoldKPI, setFilterGoldKPI] = useState("");

  // const passtoken = JSON.parse(localStorage.getItem("okta-token-storage"))[
  //   "accessToken"
  // ]["accessToken"];

  // const fetchData = async () => {
  //   try {
  //     let res = await axios.get(
  //       "https://mars-food-nutrition-world.azurewebsites.net/ai/dataDictionaries",
  //       {
  //         headers: {
  //           "oidc-token": passtoken,
  //           Accept: "application/json",
  //         },
  //       }
  //     );
  //     setDataDict(res.data);
  //     setLoading(false);
  //   } catch (e) {
  //     setLoading(false);
  //     console.log("getting error");
  //   }
  // };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  setTimeout(() => {
    setLoading(false);
  }, 2000);

  const rows = dataDict;
  const transformData = (excelData) => {
    const newArray = excelData.map((obj) => {
      const functionValue = obj["function"];

      const {
        id,
        kpiName,
        kpiOwner,
        businessDefinition,
        businessProcess,
        reportsLinked,
        goldKpis,
        formula,
        synonyms,
        aggregationRules,
        lowestDesiredGranularityCustomer,
        lowestDesiredGranularityGeography,
        lowestDesiredGranularityProductLevel,
        lowestDesiredGranularityTimePeriod,
        unitOfAggregation,
      } = obj;
      return {
        id: id,
        "KPI Name": kpiName,
        "Function Business Lens": functionValue,
        "Business Definition": businessDefinition,
        "Business Process": businessProcess,
        "Reports Linked": reportsLinked,
        "If Yes, Formula": formula,
        "KPI Owner": kpiOwner,
        "Aggregation Rules": aggregationRules,
        "Unit of Aggregation": unitOfAggregation,
        Synonyms: synonyms,
        "Lowest Desired Granularity Geography":
          lowestDesiredGranularityGeography,
        "Lowest Desired Granularity TimePeriod":
          lowestDesiredGranularityTimePeriod,
        "Lowest Desired Granularity Product Level":
          lowestDesiredGranularityProductLevel,
        "Lowest Desired Granularity Customer": lowestDesiredGranularityCustomer,
        "Gold KPIs": goldKpis,
      };
    });
    return newArray;
  };
  const sortedAsc = dataDict;
  const data = sortedAsc === null ? " " : Object.values(sortedAsc);

  // const search_parameters = [
  //   "kpiName",
  //   "businessDefinition",
  //   "function",
  //   "businessProcess",
  //   "reportsLinked",
  //   "formula",
  //   "aggregationRules",
  //   "unitOfAggregation",
  //   "synonyms",
  //   "lowestDesiredGranularityGeography",
  //   "lowestDesiredGranularityTimePeriod",
  //   "lowestDesiredGranularityProductLevel",
  //   "lowestDesiredGranularityCustomer",
  //   "goldKpis",
  // ];
  // const Filter_Portion_Size = [
  //   ...new Set(data.map((item) => item.portionsize)),
  // ];
  // const Filter_Regional = [
  //   ...new Set(data.map((item) => item["Regional product category"])),
  // ];

  const Filter_Business_Function = [
    ...new Set(data.map((item) => item.function)),
  ];
  const Filter_Gold_KPI = [...new Set(data.map((item) => item.goldKpis))];

  const finalFilteredGoldKPI = Filter_Gold_KPI.filter((item) => item !== "");
  const finalFilteredBusinessFunction = Filter_Business_Function.filter(
    (item) => item !== ""
  );

  // const finalFilteredGoldKPI = Filter_Portion_Size.filter(
  //   (item) => item !== ""
  // );
  // const finalFilteredBusinessFunction = Filter_Regional.filter(
  //   (item) => item !== ""
  // );

  let filteredData = [];

  if (sortedAsc !== null) {
    filteredData = sortedAsc.filter((obj) => {
      return obj;
    });
  }
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);

    // Filter data based on search term and selected gender
  };

  // Filter based on the search query
  if (query) {
    filteredData = filteredData.filter((item) => {
      return (
        item.kpiName.toLowerCase().includes(query.toLowerCase()) ||
        item.businessProcess.toLowerCase().includes(query.toLowerCase())
      );
    });
  }

  // Further filter based on the select inputs if they are provided
  if (filterGoldKPI || filterFunctionBusiness) {
    filteredData = filteredData.filter((item) => {
      return (
        (!filterGoldKPI || item.goldKpis.toString() === filterGoldKPI) &&
        (!filterFunctionBusiness || item.function === filterFunctionBusiness)
      );
    });
  }

  let NUM_OF_RECORDS = filteredData.length;
  if (NUM_OF_RECORDS <= currentPagelimit) {
    $(".pagination").hide(true);
  } else {
    $(".pagination").show(true);
  }

  const currentData = filteredData.slice(
    (currentPage - 1) * currentPagelimit,
    (currentPage - 1) * currentPagelimit + currentPagelimit
  );

  const pageCount = Math.ceil(filteredData.length / currentPagelimit);

  const handelepageclick = (data) => {
    setCurrentPage(data.selected + 1);
  };
  const resetAll = () => {
    handlePageChange({ selected: 1 });
    setfilterFunctionBusiness("");
    setQuery("");
    setFilterGoldKPI("");
  };

  const renderSkeletonRows = () => {
    const skeletonRows = [];
    for (let i = 0; i < 5; i++) {
      skeletonRows.push(
        <tr key={`skeleton-${i}`}>
          <td>
            <Skeleton width={150} height={35} />
          </td>
          <td>
            <Skeleton width={250} height={35} />
          </td>
          <td>
            <Skeleton width={100} height={35} />
          </td>
          <td>
            <Skeleton width={250} height={35} />
          </td>
          <td>
            <Skeleton width={100} height={35} />
          </td>
          <td>
            <Skeleton width={100} height={35} />
          </td>
          <td>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "12px",
              }}
            >
              <Skeleton variant="circular" width={35} height={35} />
              {/* <Skeleton variant="circular" width={35} height={35} />
              <Skeleton variant="circular" width={35} height={35} /> */}
            </div>
          </td>
        </tr>
      );
    }
    return skeletonRows;
  };

  // function getDateFromTimestamp(timestamp) {
  //   var date = new Date(timestamp);

  //   // Extract date components
  //   var year = date.getFullYear();
  //   var month = ("0" + (date.getMonth() + 1)).slice(-2); // Months are zero-based, so add 1
  //   var day = ("0" + date.getDate()).slice(-2);
  //   var hours = ("0" + date.getHours()).slice(-2);
  //   var minutes = ("0" + date.getMinutes()).slice(-2);
  //   var seconds = ("0" + date.getSeconds()).slice(-2);

  //   // Custom format: YYYY-MM-DD HH:MM:SS
  //   var formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

  //   return formattedDate;
  // }

  const editDataHandler = (data) => {
    setDataDict((prevData) =>
      prevData.map((item) => (item.GRD === data.GRD ? data : item))
    );
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const deleteDataHandler = (ID) => {
    setDataDict((prevData) => prevData.filter((item) => item.GRD !== ID));
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const importDataHandler = (newData) => {
    setDataDict((prevData) => newData);
    console.log("Imported Data: ", newData);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };
  return (
    <div id="homePage">
      <Box
        className="mainLayout"
        sx={{ display: "flex", overflow: "hidden", overflowY: "auto" }}
      >
        <MainLayout />

        <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
          <DrawerHeader />
          <DrawerHeader style={{ minHeight: "0px", paddingTop: "3px" }} />

          <div style={{ padding: "15px" }} id="mainHome">
            <div
              style={{ paddingBottom: "1rem", gridTemplateColumns: "100%" }}
              className="searchBarSections"
            >
              <div
                style={{
                  paddingTop: "0rem",
                  paddingRight: "1rem",
                  display: "flex",
                  gridTemplateColumns: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                className="searchBarSections"
              >
                <div
                  style={{
                    padding: "15px",
                    paddingLeft: "10px",
                    color: "#19738d",
                  }}
                >
                  <div className="heading1">Data Dictionary</div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: "16px",
                    width: "18%",
                  }}
                >
                  {/* {localStorage.getItem("role") === "Admin" && (
                      <ImportData
                        onFetchData={fetchData}
                        reportFlag="data-dict"
                      />
                    )} */}
                  {/* <ImportData
                      onFetchData={fetchData}
                      reportFlag="data-dict"
                      onImportData={importDataHandler}
                    /> */}

                  <ReportGenerate
                    excelData={dataDict}
                    fileName={`Data_Dictionary_${new Date().getTime()}`}
                    transformData={transformData}
                    sheetName="Data Dictionary"
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingBottom: "0rem",
                  flexWrap: "wrap",
                  gridGap: "10px",
                  padding: "10px",
                  paddingRight: "1rem",
                }}
              >
                <div className="search-wrapper">
                  <input
                    type="search"
                    name="search-form"
                    id="search-form"
                    value={query}
                    className="SearchBox_newworkflow"
                    placeholder="Search for Business Process or KPI Name. . . "
                    autoComplete="off"
                    onChange={(e) => {
                      setQuery(e.target.value);
                      handlePageChange({ selected: 1 });
                    }}
                  />
                </div>

                <div style={{ display: "flex", gridGap: "20px" }}>
                  <div
                    style={{ width: "200px" }}
                    className="selectParent select"
                  >
                    <select
                      value={filterGoldKPI}
                      onChange={(e) => {
                        setFilterGoldKPI(e.target.value);
                        handlePageChange({ selected: 1 });
                      }}
                      className="custom-select custom-selectworkflow custom-select-new"
                      aria-label="Filter Calculated"
                    >
                      <option value="">Select Gold KPIs</option>
                      {finalFilteredGoldKPI.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                    <span className="focus"></span>
                  </div>

                  <div
                    style={{ width: "200px" }}
                    className="selectParent select"
                  >
                    <select
                      value={filterFunctionBusiness}
                      onChange={(e) => {
                        setfilterFunctionBusiness(e.target.value);
                        handlePageChange({ selected: 1 });
                      }}
                      className="custom-select custom-selectworkflow custom-select-new"
                      aria-label="Filter status"
                    >
                      <option value="">Select Business Function</option>
                      {finalFilteredBusinessFunction.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                    <span className="focus"></span>
                  </div>

                  <Button onClick={resetAll} className="btn-reset-dict">
                    Reset
                  </Button>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "1rem 1rem",
                  paddingLeft: "5px",
                  paddingBottom: "0px",
                }}
              >
                <div
                  style={{
                    fontSize: "18px",
                    paddingBottom: "10px",
                    paddingTop: "1rem",
                    display: "flex",
                    alignItems: "center",
                    padding: "10px",
                    paddingLeft: "10px",
                    color: "#212121",
                  }}
                >
                  Total number of Data :{" "}
                  <span
                    style={{
                      fontSize: "18px",
                      fontWeight: "600",
                      color: "#bb1ccc",
                      paddingLeft: "10px",
                    }}
                  >
                    {filteredData.length}
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    gridGap: "10px",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                >
                  <div>
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={handelepageclick}
                      containerClassName={"pagination"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                      forcePage={currentPage - 1}
                    />
                  </div>
                </div>
              </div>

              <div>
                {" "}
                {currentData.slice(0, 1).map((item) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        paddingLeft: "15px",
                      }}
                    >
                      <span style={{ color: "#212121", fontWeight: "normal" }}>
                        Last Updated by :{" "}
                        <span
                          style={{
                            color: "#bb1ccc",
                            fontWeight: "600",
                            padding: "2px",
                            borderRadius: "3px",
                            paddingLeft: "6px",
                            paddingRight: "6px",
                            fontSize: "18px",
                          }}
                        >
                          abc@def.com
                        </span>{" "}
                      </span>
                      {/* <span style={{ color: "#19738d", fontWeight: "700" }}>
                          Last Updated At :{" "}
                          <span
                            style={{
                              color: "#113705",
                              fontWeight: "600",
                              background: "#8ce671",
                              padding: "2px",
                              borderRadius: "3px",
                              paddingLeft: "6px",
                              paddingRight: "6px",
                              fontSize: "14px",
                            }}
                          >
                            {getDateFromTimestamp(item.createdAt)}
                          </span>
                        </span> */}
                    </div>
                  );
                })}
              </div>
            </div>
            <TableContainer
              id="dataTableContainer"
              component={Paper}
              style={{ boxShadow: "none" }}
            >
              <Table id="chatTable" aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    {tableHeaderinfo.map((item, index) => {
                      return <TableCell align="left">{item}</TableCell>;
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    renderSkeletonRows()
                  ) : currentData && currentData.length > 0 ? (
                    currentData.map((row, index) => (
                      <Row
                        // fetchData={fetchData}
                        key={row.name}
                        index={index}
                        row={row}
                        data={currentData}
                        onEditData={editDataHandler}
                        onDeleteData={deleteDataHandler}
                      />
                    ))
                  ) : (
                    <TableRow style={{ padding: "16px" }}>
                      <TableCell
                        style={{
                          fontSize: "20px",
                          color: "#212121",
                          color: "#ee4b2b",
                        }}
                        colSpan={tableHeaderinfo.length}
                        align="center"
                      >
                        No Data Found. . .
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default DataDirectoryTable;
