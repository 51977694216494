import axios from "axios";
import React, { useState } from "react";
import  Button  from "@mui/material/Button";

const DeleteModel = ({ rowID, setDeleteModel, onFetchData, onDataChange }) => {
  const [loading, setLoading] = useState(false);
  // const passtoken = JSON.parse(localStorage.getItem("okta-token-storage"))[
  //   "accessToken"
  // ]["accessToken"];

  const closeModel = () => {
    setDeleteModel(false);
  };

  const handleDelete = async () => {
    setLoading(true);
    // const data = {};
    // try {
    //   const response = await axios.post(
    //     `${process.env.REACT_APP_BASE_URL}/ai/deleteDataDict?id=${rowID}`,
    //     data,
    //     {
    //       headers: {
    //         "oidc-token": passtoken,
    //         Accept: "application/json",
    //       },
    //     }
    //   );
    //   console.log("Response:", response.data);
    //   // Handle successful response (e.g., show a success message or redirect)
    //   setIsloading(false);
    //   setDeleteModel(false);
    //   onFetchData();
    // } catch (error) {
    //   console.error("Error:", error);
    //   // Handle error (e.g., show an error message)
    // }
    setTimeout(() => {
      onDataChange(rowID);
      setLoading(false);
      closeModel();
      setDeleteModel(false);
    }, 2000);
  };

  return (
    <div className="main-container edit-dict-cont">
      <div style={{ display: "grid", gap: "0" }}>
        <span
          style={{
            fontSize: "20px",
            fontWeight: "normal",
            padding: "1rem",
            backgroundColor: "#bb1ccc",
            color: "#fff",
          }}
        >
          Are you sure you want to delete?
        </span>
        <span
          style={{ fontSize: "18px", fontWeight: "normal", padding: "1rem" }}
        >
          This action cannot be undone. Once deleted, the data will be
          permanently lost.
        </span>

        <div
          style={{
            gap: "10px",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            padding: "1rem",
          }}
        >
          <Button
            onClick={closeModel}
            disabled={loading}
            className="btn secondary-btn"
            style={{ maxWidth: "70px", minWidth: "70px", padding: "8px" }}
          >
            No
          </Button>
          <Button
            disabled={loading}
            onClick={handleDelete}
            style={{ maxWidth: "120px", minWidth: "120px", padding: "8px" }}
            className="btn primary-btn"
          >
            {loading ? (
              <div
                style={{
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span style={{ paddingLeft: "10px", fontSize:'14px' }}>Deleting. . .</span>
              </div>
            ) : (
              "Yes"
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DeleteModel;
