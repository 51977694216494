import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, withRouter } from "react-router-dom";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { LoginCallback, Security } from "@okta/okta-react";
import SigninWidget from "./app/auth/SigninWidget";
import Home from "./pages/Home/Home";
import KnowledgeGraph from "./pages/KnowledgeGraph/KnowledgeGraph";
import Dashboard from "./pages/Dashboard/Dashboard";
import AadiBots from "./pages/Aadi/AadiBots";
import Favorite from "./pages/Favorite/Favorite";
import HistoryPage from "./pages/History/HistoryPage";
import Workbench from "./pages/Workbench/Workbench";
import DataDictionary from "./pages/Data Dictionary/DataDictionary";

const oktaAuth = new OktaAuth({
  issuer: `${process.env.REACT_APP_OKTA_ISSUER}`,
  clientId: `${process.env.REACT_APP_OKTA_CLIENT_ID}`,
  redirectUri: window.location.origin + "/login/callback",
});

const App = (props) => {
  // Function to restore the original URI
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    props.history.replace(
      toRelativeUrl(originalUri || "/", window.location.origin)
    );
  };

  useEffect(() => {
    localStorage.setItem("historyListFlag", true);
    // localStorage.setItem("historyData", null);
    // localStorage.setItem("activeItem", null);
    localStorage.setItem("historyCollapse", false)
  }, []);

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Route path="/" exact={true} component={SigninWidget} />
      <Route path="/login/callback" component={LoginCallback} />
      <Route path="/Home" component={Home} />
      <Route path="/KnowledgeGraph" component={KnowledgeGraph} />
      <Route path="/Workbench" component={Workbench} />
      <Route path="/Dashboard" component={Favorite} />
      <Route path="/history" component={HistoryPage} />
      <Route path="/Data-Dictionary" component={DataDictionary} />
    </Security>
  );
};

const AppWithRouterAccess = withRouter(App);

const RouterApp = () => {
  return (
    <Router>
      <AppWithRouterAccess />
    </Router>
  );
};

export default RouterApp;
