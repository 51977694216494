// import Picture3 from '../src/components/assets/img/img1.jpeg';



const HomeData = [
    {
        "id": 3174,
        "kpiName": "Cut volume",
        "businessDefinition": "# of cases not shipped to customer from a given order",
        "function": "Customer Service and Order Fulfillment",
        "businessProcess": "Measure past performance",
        "reportsLinked": "Customer Case Fill Dashboard 2.0",
        "formula": "∑Ordered Cases - ∑Shipped Cases\n∑Ordered GSV - ∑Shipped GSV",
        "aggregationRules": "Item",
        "unitOfAggregation": "Cases, GSV",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "Plant level",
        "lowestDesiredGranularityTimePeriod": "Daily",
        "lowestDesiredGranularityProductLevel": "Material level",
        "lowestDesiredGranularityCustomer": "Customer level",
        "goldKpis": "Yes",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3175,
        "kpiName": "Fill rate",
        "businessDefinition": "Fill rate or order fulfillment rate, is the rate of orders that can be shipped from available inventory without missing out on purchases due to stockouts, backorders, lost sales, etc",
        "function": "Customer Service and Order Fulfillment",
        "businessProcess": "Measure past performance",
        "reportsLinked": "SPM V2 PROD (Use This)",
        "formula": "(∑Shipped Cases / ∑Ordered Cases) * 100",
        "aggregationRules": "Item",
        "unitOfAggregation": "Cases (MD)",
        "synonyms": "CSL (Case Fill Rate)",
        "lowestDesiredGranularityGeography": "N/A",
        "lowestDesiredGranularityTimePeriod": "Daily",
        "lowestDesiredGranularityProductLevel": "Material level",
        "lowestDesiredGranularityCustomer": "Customer level",
        "goldKpis": "No",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3176,
        "kpiName": "Fill rate on time",
        "businessDefinition": "Fill rate or order fulfillment rate on time, is the % of orders that can be shipped from available inventory without missing out on purchases due to stockouts, backorders, lost sales, etc AND are delivered to the customer at the time agreed",
        "function": "Customer Service and Order Fulfillment",
        "businessProcess": "Measure past performance",
        "reportsLinked": "SPM V2 PROD (Use This)",
        "formula": "Fill rate minus late fills",
        "aggregationRules": "Item",
        "unitOfAggregation": "Cases",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "N/A",
        "lowestDesiredGranularityTimePeriod": "Daily",
        "lowestDesiredGranularityProductLevel": "Material level",
        "lowestDesiredGranularityCustomer": "Customer level",
        "goldKpis": "Yes",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3177,
        "kpiName": "Layer Ordering Compliance",
        "businessDefinition": "Measure of what fraction of total shipments are orderd in full layers ( 1 layer= 21cases)",
        "function": "Customer Service and Order Fulfillment",
        "businessProcess": "Customer Compliance Management",
        "reportsLinked": "2023 LCC feeder template",
        "formula": "Line items not ordered in layers/Total line items ordered from a DC",
        "aggregationRules": "",
        "unitOfAggregation": "",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "Plant/DC level",
        "lowestDesiredGranularityTimePeriod": "Period level",
        "lowestDesiredGranularityProductLevel": "N/A",
        "lowestDesiredGranularityCustomer": "Customer level",
        "goldKpis": "No",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3178,
        "kpiName": "Order cycle time",
        "businessDefinition": "From the time orders are placed till the time it is shipped out from warehouse",
        "function": "Customer Service and Order Fulfillment",
        "businessProcess": "Logistics Cost Management",
        "reportsLinked": "TBD",
        "formula": "∑(Order received time- Delivered time)",
        "aggregationRules": "Item",
        "unitOfAggregation": "Time (Days)",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "N/A",
        "lowestDesiredGranularityTimePeriod": "N/A",
        "lowestDesiredGranularityProductLevel": "Item",
        "lowestDesiredGranularityCustomer": "Customer level",
        "goldKpis": "Yes",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3179,
        "kpiName": "Ordered Volume",
        "businessDefinition": "# of cases ordered for a given material in a sales document",
        "function": "Customer Service and Order Fulfillment",
        "businessProcess": "Measure past performance",
        "reportsLinked": "Customer Case Fill Dashboard 2.0",
        "formula": "∑Ordered Cases\n∑Ordered GSV\n",
        "aggregationRules": "Item",
        "unitOfAggregation": "Cases, GSV",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "Plant/DC level",
        "lowestDesiredGranularityTimePeriod": "Daily",
        "lowestDesiredGranularityProductLevel": "Material level",
        "lowestDesiredGranularityCustomer": "Customer level",
        "goldKpis": "No",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3180,
        "kpiName": "OTIF - On Time in Full",
        "businessDefinition": "Measure of supplier’s ability to fulfill its delivery promises, meaning a customer receives exactly what was ordered, in the amount requested, at the correct location, and within the agreed upon timeframe.",
        "function": "Customer Service and Order Fulfillment",
        "businessProcess": "Measure past performance",
        "reportsLinked": "SPM V2 PROD (Use This)",
        "formula": "(∑Orders Supplied on Time in full / ∑Orders received) *100 ->How is on-time being measured?",
        "aggregationRules": "Item",
        "unitOfAggregation": "Cases",
        "synonyms": "RAD = recommended appointment date",
        "lowestDesiredGranularityGeography": "DC/Plant",
        "lowestDesiredGranularityTimePeriod": "Weekly",
        "lowestDesiredGranularityProductLevel": "Material level",
        "lowestDesiredGranularityCustomer": "Customer level",
        "goldKpis": "No",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3181,
        "kpiName": "Shipped volume",
        "businessDefinition": "# of cases shipped for a given material in an invoice",
        "function": "Customer Service and Order Fulfillment",
        "businessProcess": "Measure past performance",
        "reportsLinked": "Customer Case Fill Dashboard 2.0",
        "formula": "∑Shipped Cases",
        "aggregationRules": "Item",
        "unitOfAggregation": "Cases, GSV",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "Plant/DC level",
        "lowestDesiredGranularityTimePeriod": "Daily",
        "lowestDesiredGranularityProductLevel": "Material level",
        "lowestDesiredGranularityCustomer": "Customer level",
        "goldKpis": "No",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3182,
        "kpiName": "Allocation Cases",
        "businessDefinition": "# of cases that can be ordered for the item/customer combo",
        "function": "Customer Service and Order Fulfillment",
        "businessProcess": "S&OE",
        "reportsLinked": "Daily Allocation & Quota Report\nATLAS AM: LAP SCM Production",
        "formula": "∑Maximum Capacity",
        "aggregationRules": "",
        "unitOfAggregation": "Cases",
        "synonyms": "Quota",
        "lowestDesiredGranularityGeography": "N/A",
        "lowestDesiredGranularityTimePeriod": "-Daily for reporting\n-Allocation is setup at the period level",
        "lowestDesiredGranularityProductLevel": "Material level",
        "lowestDesiredGranularityCustomer": "Customer level",
        "goldKpis": "",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3183,
        "kpiName": "Confirmed Volume",
        "businessDefinition": "# of cases/GSV confirming on a sales order that has not goods issued yet",
        "function": "Customer Service and Order Fulfillment",
        "businessProcess": " ",
        "reportsLinked": "Customer Case Fill Dashboard 2.0",
        "formula": "∑Confirmed Cases\n∑Confirmed GSV",
        "aggregationRules": "",
        "unitOfAggregation": "Cases, GSV",
        "synonyms": " ",
        "lowestDesiredGranularityGeography": "Plant/DC level",
        "lowestDesiredGranularityTimePeriod": "Daily",
        "lowestDesiredGranularityProductLevel": "Material level",
        "lowestDesiredGranularityCustomer": "Customer level",
        "goldKpis": "",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3184,
        "kpiName": "Confirmed Weight",
        "businessDefinition": "Pounds (lbs.) confirming for a given material in a sales document",
        "function": "Customer Service and Order Fulfillment",
        "businessProcess": "Measure past performance",
        "reportsLinked": "Customer Case Fill Dashboard 2.0",
        "formula": "∑Confirming cases pounds (lbs.)",
        "aggregationRules": "",
        "unitOfAggregation": "Pounds",
        "synonyms": " ",
        "lowestDesiredGranularityGeography": "Plant/DC level",
        "lowestDesiredGranularityTimePeriod": "Daily",
        "lowestDesiredGranularityProductLevel": "Material level",
        "lowestDesiredGranularityCustomer": "Customer level/Ship to",
        "goldKpis": "",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3185,
        "kpiName": "Consumed Allocation",
        "businessDefinition": "# of cases confirming or shipped on orders for the item/customer combo",
        "function": "Customer Service and Order Fulfillment",
        "businessProcess": "S&OE",
        "reportsLinked": "Daily Allocation & Quota Report\nATLAS AM: LAP SCM Production",
        "formula": "∑Quota Consumed Qty",
        "aggregationRules": "",
        "unitOfAggregation": "Cases",
        "synonyms": "Quota Consumed",
        "lowestDesiredGranularityGeography": "N/A",
        "lowestDesiredGranularityTimePeriod": "-Daily for reporting\n-Allocation is setup at the period level",
        "lowestDesiredGranularityProductLevel": "Material level",
        "lowestDesiredGranularityCustomer": "Customer level",
        "goldKpis": "",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3186,
        "kpiName": "Consumed Allocation %",
        "businessDefinition": "% of allocation that has is confirming or shipped on orders to the item/customer combo",
        "function": "Customer Service and Order Fulfillment",
        "businessProcess": "S&OE",
        "reportsLinked": "Daily Allocation & Quota Report\nATLAS AM: LAP SCM Production",
        "formula": "∑Quota Consumed Qty / '∑Maximum Capacity",
        "aggregationRules": "",
        "unitOfAggregation": "Cases",
        "synonyms": "Quota Consumed %",
        "lowestDesiredGranularityGeography": "N/A",
        "lowestDesiredGranularityTimePeriod": "-Daily for reporting\n-Allocation is setup at the period level",
        "lowestDesiredGranularityProductLevel": "Material level",
        "lowestDesiredGranularityCustomer": "Customer level",
        "goldKpis": "",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3187,
        "kpiName": "Ordered Weight",
        "businessDefinition": "Pounds (lbs.) ordered for a given material in a sales document",
        "function": "Customer Service and Order Fulfillment",
        "businessProcess": "Measure past performance",
        "reportsLinked": "Customer Case Fill Dashboard 2.0",
        "formula": "∑Ordered cases pounds (lbs.)",
        "aggregationRules": "",
        "unitOfAggregation": "Pounds",
        "synonyms": " ",
        "lowestDesiredGranularityGeography": "Plant/DC level",
        "lowestDesiredGranularityTimePeriod": "Daily",
        "lowestDesiredGranularityProductLevel": "Material level",
        "lowestDesiredGranularityCustomer": "Customer level/Ship to",
        "goldKpis": "",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3188,
        "kpiName": "Potential Cuts",
        "businessDefinition": "# of cases/GSV not confirming on a sales order that has not goods issued yet",
        "function": "Customer Service and Order Fulfillment",
        "businessProcess": " ",
        "reportsLinked": "Customer Case Fill Dashboard 2.0",
        "formula": "∑Ordered Cases - Confirmed Cases\n∑Ordered GSV - Confirmed GSV",
        "aggregationRules": "",
        "unitOfAggregation": "Cases, GSV",
        "synonyms": " ",
        "lowestDesiredGranularityGeography": "Plant/DC level",
        "lowestDesiredGranularityTimePeriod": "Daily",
        "lowestDesiredGranularityProductLevel": "Material level",
        "lowestDesiredGranularityCustomer": "Customer level",
        "goldKpis": "",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3189,
        "kpiName": "Remaining Allocation",
        "businessDefinition": "# of cases remaining that can be ordered for the item/customer combo",
        "function": "Customer Service and Order Fulfillment",
        "businessProcess": "S&OE",
        "reportsLinked": "Daily Allocation & Quota Report\nATLAS AM: LAP SCM Production",
        "formula": "∑Maximum Capacity - ∑Quota Consumed Qty",
        "aggregationRules": "",
        "unitOfAggregation": "Cases",
        "synonyms": "Quota Remaining",
        "lowestDesiredGranularityGeography": "N/A",
        "lowestDesiredGranularityTimePeriod": "-Daily for reporting\n-Allocation is setup at the period level",
        "lowestDesiredGranularityProductLevel": "Material level",
        "lowestDesiredGranularityCustomer": "Customer level",
        "goldKpis": "",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3190,
        "kpiName": "Shipped Weight",
        "businessDefinition": "Pounds (lbs.) shipped for a given material in a sales document",
        "function": "Customer Service and Order Fulfillment",
        "businessProcess": "Measure past performance",
        "reportsLinked": "Customer Case Fill Dashboard 2.0",
        "formula": "∑Confirming cases pounds (lbs.)",
        "aggregationRules": "",
        "unitOfAggregation": "Pounds",
        "synonyms": " ",
        "lowestDesiredGranularityGeography": "Plant/DC level",
        "lowestDesiredGranularityTimePeriod": "Daily",
        "lowestDesiredGranularityProductLevel": "Material level",
        "lowestDesiredGranularityCustomer": "Customer level/Ship to",
        "goldKpis": "",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3191,
        "kpiName": "Case fill",
        "businessDefinition": "refers to order shipped with respect to the total agreed orders",
        "function": "Customer Service and Order Fulfillment",
        "businessProcess": "Measure past performance",
        "reportsLinked": "",
        "formula": "∑Shipped Cases / ∑Agreed Ordered Cases",
        "aggregationRules": "Item",
        "unitOfAggregation": "",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "N/A",
        "lowestDesiredGranularityTimePeriod": "Period level",
        "lowestDesiredGranularityProductLevel": "Material",
        "lowestDesiredGranularityCustomer": "Customer level",
        "goldKpis": "No",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3192,
        "kpiName": "FSC (Fuel Surcharge)",
        "businessDefinition": "An extra fee, determined as a percentage of the base rate, charged by transport companies to allow for the fluctuating costs of fuel. (per tonne)",
        "function": "Logistics (Transportation)",
        "businessProcess": "Logistics Cost Management",
        "reportsLinked": "2023 LCC feeder template",
        "formula": "Total fuel surcharge incurred for shipments/ Total tonnage of shipments",
        "aggregationRules": "",
        "unitOfAggregation": "",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "N/A",
        "lowestDesiredGranularityTimePeriod": "Period level",
        "lowestDesiredGranularityProductLevel": "N/A",
        "lowestDesiredGranularityCustomer": "N/A",
        "goldKpis": "No",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3193,
        "kpiName": "FTC (Freight to Customer)",
        "businessDefinition": "Per tonne cost to move finished goods from warehouse to customer",
        "function": "Logistics (Transportation)",
        "businessProcess": "Report Performance",
        "reportsLinked": "2023 LCC feeder template",
        "formula": "Linehaul + FSC / Tonnage shipped to customer",
        "aggregationRules": "Invoice",
        "unitOfAggregation": "Cost",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "N/A",
        "lowestDesiredGranularityTimePeriod": "Period level",
        "lowestDesiredGranularityProductLevel": "N/A",
        "lowestDesiredGranularityCustomer": "N/A",
        "goldKpis": "Yes",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3194,
        "kpiName": "FTW (Freight to Warehouse)",
        "businessDefinition": "Per tonne cost to move finished goods between warehouses",
        "function": "Logistics (Transportation)",
        "businessProcess": "Report Performance",
        "reportsLinked": "2023 LCC feeder template",
        "formula": "Linehaul + FSC / Tonnage shipped to warehouses",
        "aggregationRules": "Invoice",
        "unitOfAggregation": "Cost",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "N/A",
        "lowestDesiredGranularityTimePeriod": "Period level",
        "lowestDesiredGranularityProductLevel": "N/A",
        "lowestDesiredGranularityCustomer": "N/A",
        "goldKpis": "Yes",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3195,
        "kpiName": "Linehaul",
        "businessDefinition": "The expenses that occurred on haulage or transportation of freight from pickup point to the destination. (per tonne)",
        "function": "Logistics (Transportation)",
        "businessProcess": "Logistics Cost Management",
        "reportsLinked": "2023 LCC feeder template",
        "formula": "Total transportation cost of shipments/ Total tonnage of shipments",
        "aggregationRules": "",
        "unitOfAggregation": "",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "N/A",
        "lowestDesiredGranularityTimePeriod": "Period level",
        "lowestDesiredGranularityProductLevel": "N/A",
        "lowestDesiredGranularityCustomer": "N/A",
        "goldKpis": "No",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3196,
        "kpiName": "Truck Utilization",
        "businessDefinition": "Description of how much of the vehicle capacity is utilized. (overall capacity over a period)",
        "function": "Logistics (Transportation)",
        "businessProcess": "There are 2xPower BI tools called \"US Logistics LOE\" and \"Logistics Operational Efficiency report EU\"",
        "reportsLinked": "2023 LCC feeder template",
        "formula": "∑Order Shipped/(∑Total Truck Capacity)",
        "aggregationRules": "Item",
        "unitOfAggregation": "Metric Tonnes",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "Plant/DC level",
        "lowestDesiredGranularityTimePeriod": "Periodically",
        "lowestDesiredGranularityProductLevel": "N/A",
        "lowestDesiredGranularityCustomer": "N/A",
        "goldKpis": "No",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3197,
        "kpiName": "Average Order Weight",
        "businessDefinition": "average weight of an order / shipment ",
        "function": "Logistics (Warehousing)",
        "businessProcess": "Logistics Cost Management",
        "reportsLinked": "Bracket Scorecard",
        "formula": "∑Weight of all the orders/∑No. of shipments orders)",
        "aggregationRules": "Item",
        "unitOfAggregation": " MT",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "N/A",
        "lowestDesiredGranularityTimePeriod": "N/A",
        "lowestDesiredGranularityProductLevel": "N/A",
        "lowestDesiredGranularityCustomer": "Customer level/Sold to",
        "goldKpis": "No",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3198,
        "kpiName": "Handling Cost",
        "businessDefinition": "Handling costs refer to the types of costs associated with preparing and transporting inventory. Mostly, the cost cover expenses related to order fulfilment such as shipment and packaging cost.",
        "function": "Logistics (Warehousing)",
        "businessProcess": "Warehouse Cost Management",
        "reportsLinked": "S&H graphs",
        "formula": "∑Total Variable cost/Avg stocks per day",
        "aggregationRules": "Cost & Item",
        "unitOfAggregation": "USD & Cases",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "DC level",
        "lowestDesiredGranularityTimePeriod": "Periodically",
        "lowestDesiredGranularityProductLevel": "N/A",
        "lowestDesiredGranularityCustomer": "N/A",
        "goldKpis": "Yes",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3199,
        "kpiName": "Pallet Capacity",
        "businessDefinition": "Maximum no of items that a pallet can accomodate",
        "function": "Logistics (Warehousing)",
        "businessProcess": "Inventory Health Management",
        "reportsLinked": "Inventory by DC Report",
        "formula": "Total Space available/1 Pallet space",
        "aggregationRules": "Space",
        "unitOfAggregation": "Sq Ft",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "DC",
        "lowestDesiredGranularityTimePeriod": "Periodically",
        "lowestDesiredGranularityProductLevel": "N/A",
        "lowestDesiredGranularityCustomer": "N/A",
        "goldKpis": "No",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3200,
        "kpiName": "Pallet Projections",
        "businessDefinition": "Number of pallets being projected to be manufactured or shipped",
        "function": "Logistics (Warehousing)",
        "businessProcess": "Inventory Health Management",
        "reportsLinked": "Warehouse Inventory Projections 12.18.23 part 2",
        "formula": "∑Projected Inventory in Pallets",
        "aggregationRules": "Cases",
        "unitOfAggregation": "Pallets",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "DC",
        "lowestDesiredGranularityTimePeriod": "Periodically",
        "lowestDesiredGranularityProductLevel": "N/A",
        "lowestDesiredGranularityCustomer": "N/A",
        "goldKpis": "Yes",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3201,
        "kpiName": "Storage Cost",
        "businessDefinition": "Storage costs are the amount of money incurred as a result of storing inventory. The costs could be direct or indirect money spent on the storage of goods.",
        "function": "Logistics (Warehousing)",
        "businessProcess": "Warehouse Cost Management",
        "reportsLinked": "S&H graphs",
        "formula": "∑Warehouse Fixed cost",
        "aggregationRules": "Cost",
        "unitOfAggregation": "USD",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "DC level",
        "lowestDesiredGranularityTimePeriod": "Periodically",
        "lowestDesiredGranularityProductLevel": "N/A",
        "lowestDesiredGranularityCustomer": "N/A",
        "goldKpis": "Yes",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3202,
        "kpiName": "Warehouse Throughput (pallets, cases)",
        "businessDefinition": "Number of units processed per time interval. Throughput typically quantifies the outbound operations within a warehouse.",
        "function": "Logistics (Warehousing)",
        "businessProcess": "Manage Logistics Costs",
        "reportsLinked": "S&H graphs",
        "formula": "Total number of inbound/outboud shipment units processed divided by total man-hours ",
        "aggregationRules": "",
        "unitOfAggregation": "Cases, Pallets",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "Plant/DC level",
        "lowestDesiredGranularityTimePeriod": "Periodically",
        "lowestDesiredGranularityProductLevel": "N/A",
        "lowestDesiredGranularityCustomer": "N/A",
        "goldKpis": "No",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3203,
        "kpiName": "Warehouse Utilization - Actuals",
        "businessDefinition": "Occupancy of warehouse pallets against total capacity",
        "function": "Logistics (Warehousing)",
        "businessProcess": "Measure past performance",
        "reportsLinked": "Warehouse Inventory Projections 12.18.23 part 2",
        "formula": "∑Warehouse Space Used /∑Warehouse Space Available) x 100",
        "aggregationRules": "Area",
        "unitOfAggregation": "Cases converted into Sqft",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "Plant/DC level",
        "lowestDesiredGranularityTimePeriod": "Periodically",
        "lowestDesiredGranularityProductLevel": "N/A",
        "lowestDesiredGranularityCustomer": "N/A",
        "goldKpis": "Yes",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3204,
        "kpiName": "Warehouse Utilization - Projected",
        "businessDefinition": "Forecasted occupancy of warehouse pallets against total capacity",
        "function": "Logistics (Warehousing)",
        "businessProcess": "Calculates projections",
        "reportsLinked": "Warehouse Inventory Projections 12.18.23 part 2",
        "formula": "∑Warehouse Space Used /∑Warehouse Space Available) x 100",
        "aggregationRules": "Area",
        "unitOfAggregation": "Cases converted into Sqft",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "Plant/DC level",
        "lowestDesiredGranularityTimePeriod": "Periodically",
        "lowestDesiredGranularityProductLevel": "N/A",
        "lowestDesiredGranularityCustomer": "N/A",
        "goldKpis": "Yes",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3205,
        "kpiName": "OTR (On-Time to RAD)",
        "businessDefinition": "The On-time delivery performance refers to the ratio of customer order lines shipped on or before the requested delivery date / customer promised date versus the total number of order lines.",
        "function": "Logistics (Transportation) and Customer Service and Order Fulfillment",
        "businessProcess": "Report Performance",
        "reportsLinked": "2023 LCC feeder template",
        "formula": "(∑Orders Supplied on Time / ∑Orders received) *100",
        "aggregationRules": "Item",
        "unitOfAggregation": "Cases",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "DC/Plant",
        "lowestDesiredGranularityTimePeriod": "Weekly",
        "lowestDesiredGranularityProductLevel": "Item",
        "lowestDesiredGranularityCustomer": "Customer level",
        "goldKpis": "Yes",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3206,
        "kpiName": "LCC - Logistics Conversion Cost",
        "businessDefinition": "Overall cost of logistics including Freight to Warehouse & Freight to Customer",
        "function": "Logistics (Warehousing) and Logistics (Transportation)",
        "businessProcess": "Manage Logistics Costs",
        "reportsLinked": "S&OP SCMR_PROD",
        "formula": "∑Logistics cost - ∑FTW + ∑FTC",
        "aggregationRules": "Invoice",
        "unitOfAggregation": "Cost",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "DC/Plant",
        "lowestDesiredGranularityTimePeriod": "Weekly",
        "lowestDesiredGranularityProductLevel": "Item",
        "lowestDesiredGranularityCustomer": "Customer level",
        "goldKpis": "Yes",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3207,
        "kpiName": "Logsitics Cost per Tonne",
        "businessDefinition": "Shipping cost for every tonne of FG moved ",
        "function": "Logistics (Warehousing) and Logistics (Transportation)",
        "businessProcess": "Report Performance",
        "reportsLinked": "2023 LCC feeder template",
        "formula": "∑Transportation Costs / ∑Tonnes shipped",
        "aggregationRules": "Invoice",
        "unitOfAggregation": "Cost & Metric Tonnes",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "DC/Plant",
        "lowestDesiredGranularityTimePeriod": "Weekly",
        "lowestDesiredGranularityProductLevel": "Item",
        "lowestDesiredGranularityCustomer": "Customer level",
        "goldKpis": "Yes",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3208,
        "kpiName": "Cuts projection (0-12 weeks) ",
        "businessDefinition": "Measured in both cases as well as a % of demand",
        "function": "Supply Planning",
        "businessProcess": "S&OE",
        "reportsLinked": "SPM V2 PROD (Use This)",
        "formula": "∑Projected Orders - ∑Projected allocation",
        "aggregationRules": "Item",
        "unitOfAggregation": "Cases, GSV",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "N/A",
        "lowestDesiredGranularityTimePeriod": "Weekly",
        "lowestDesiredGranularityProductLevel": "Material level",
        "lowestDesiredGranularityCustomer": "Customer level",
        "goldKpis": "Yes",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3209,
        "kpiName": "Customer Service Level (CSL) Projected",
        "businessDefinition": "Forecast of CSL for next N periods",
        "function": "Supply Planning",
        "businessProcess": "Measure Supply At Risk",
        "reportsLinked": "Projected Cut Dashboard",
        "formula": "∑Shipped Cases / ∑Ordered Cases",
        "aggregationRules": "Item",
        "unitOfAggregation": "Cases (MD)",
        "synonyms": "Case Fill rate %",
        "lowestDesiredGranularityGeography": "Plant/DC level",
        "lowestDesiredGranularityTimePeriod": "Weekly",
        "lowestDesiredGranularityProductLevel": "Material level",
        "lowestDesiredGranularityCustomer": "Customer level",
        "goldKpis": "Yes",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3210,
        "kpiName": "OFA - Order Forecast Accuracy",
        "businessDefinition": "Means of measuring how well a demand forecast has predicted actual outcomes or values of orders",
        "function": "Supply Planning",
        "businessProcess": "Measure past performance",
        "reportsLinked": "SFA and BIAS Dashboard 2.0",
        "formula": "1 – [Absolute value of (∑Actual orders  – ∑Forecast orders) / ∑Actual orders] for same time period",
        "aggregationRules": "Item",
        "unitOfAggregation": "Cases (Claire)",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "Plant",
        "lowestDesiredGranularityTimePeriod": "Period",
        "lowestDesiredGranularityProductLevel": "Material level",
        "lowestDesiredGranularityCustomer": "National",
        "goldKpis": "Yes",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3211,
        "kpiName": "DFC (Days forward coverage) cases",
        "businessDefinition": "Amount of inventory (cases) available in terms of days",
        "function": "Supply Planning",
        "businessProcess": "Inventory Health",
        "reportsLinked": "Warehouse Inventory Projections 12.18.23 part 2",
        "formula": "∑Inventory  / average proj daily sales (as per 7 Days)",
        "aggregationRules": "Item",
        "unitOfAggregation": "Cases, GSV, COGS",
        "synonyms": "DOI, DOS, DOH",
        "lowestDesiredGranularityGeography": "Plant/DC level",
        "lowestDesiredGranularityTimePeriod": "Weekly",
        "lowestDesiredGranularityProductLevel": "N/A",
        "lowestDesiredGranularityCustomer": "N/A",
        "goldKpis": "Yes",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3212,
        "kpiName": "ATS (Adherence to schedule)",
        "businessDefinition": "Production schedule adherence ",
        "function": "Supply Planning",
        "businessProcess": "Factory Performance",
        "reportsLinked": "ATS New Format",
        "formula": "(∑Planned - ∑actual)/ ∑planned",
        "aggregationRules": "Item",
        "unitOfAggregation": "Cases",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "Plant/DC level",
        "lowestDesiredGranularityTimePeriod": "Weekly",
        "lowestDesiredGranularityProductLevel": "N/A",
        "lowestDesiredGranularityCustomer": "N/A",
        "goldKpis": "Yes",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3213,
        "kpiName": "ATP (Adherence to plan)",
        "businessDefinition": "how much is produced against planning provided",
        "function": "Supply Planning",
        "businessProcess": "Factory Performance",
        "reportsLinked": "Global RTH ATP - 2023",
        "formula": "(∑Planned - ∑actual)/ ∑planned",
        "aggregationRules": "Item",
        "unitOfAggregation": "Cases",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "Plant/DC level",
        "lowestDesiredGranularityTimePeriod": "Weekly",
        "lowestDesiredGranularityProductLevel": "N/A",
        "lowestDesiredGranularityCustomer": "N/A",
        "goldKpis": "Yes",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3214,
        "kpiName": "Projected Demand",
        "businessDefinition": "predict the future demand for a product or service",
        "function": "Supply Planning",
        "businessProcess": "Product Supply Plan",
        "reportsLinked": "RTH stock level pivot, Demand comparison Food NA P1W3 (updated)",
        "formula": "∑Demand-Proj",
        "aggregationRules": "Item",
        "unitOfAggregation": "Cases, GSV",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "Plant/DC level",
        "lowestDesiredGranularityTimePeriod": "Weekly",
        "lowestDesiredGranularityProductLevel": "N/A",
        "lowestDesiredGranularityCustomer": "N/A",
        "goldKpis": "Yes",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3215,
        "kpiName": "Through put",
        "businessDefinition": "Rate of production in unit time",
        "function": "Supply Planning",
        "businessProcess": "Supply Planning",
        "reportsLinked": "TBD",
        "formula": "∑FG Units produced/Time in days or hours",
        "aggregationRules": "Items",
        "unitOfAggregation": "Cases",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "Production Line",
        "lowestDesiredGranularityTimePeriod": "Weekly",
        "lowestDesiredGranularityProductLevel": "N/A",
        "lowestDesiredGranularityCustomer": "National",
        "goldKpis": "No",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3216,
        "kpiName": "Production line utilization",
        "businessDefinition": "% capacity of maximum at which production lines are operating",
        "function": "Supply Planning",
        "businessProcess": "Product Supply Plan",
        "reportsLinked": "RTH Capacity feeder, Simulation tool NA 2024P1W3, Forecast vs capacity RTH",
        "formula": "(∑Planned production in tonnage/Total line capacity in tonnage)*100",
        "aggregationRules": "Items",
        "unitOfAggregation": "Tonnage",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "Production Line",
        "lowestDesiredGranularityTimePeriod": "Weekly",
        "lowestDesiredGranularityProductLevel": "N/A",
        "lowestDesiredGranularityCustomer": "National",
        "goldKpis": "Yes",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3217,
        "kpiName": "Cutroll",
        "businessDefinition": "cutroll is the projected cuts plus 50% (estimated reorders ) of the cut total",
        "function": "Supply Planning",
        "businessProcess": "Adjust Forecast",
        "reportsLinked": "24 P1 W3 D2 Projected Cuts Report RL CMVM",
        "formula": "Projected cuts + 50% cuts of reorder",
        "aggregationRules": "",
        "unitOfAggregation": "",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "Production Line",
        "lowestDesiredGranularityTimePeriod": "Period level",
        "lowestDesiredGranularityProductLevel": "Item/SKU",
        "lowestDesiredGranularityCustomer": "National",
        "goldKpis": "No",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3218,
        "kpiName": "Supply chain loss *",
        "businessDefinition": "losses incurred due to donate , destroy",
        "function": "Supply Planning",
        "businessProcess": "Inventory Health",
        "reportsLinked": "TBD",
        "formula": "No",
        "aggregationRules": "",
        "unitOfAggregation": "",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "",
        "lowestDesiredGranularityTimePeriod": "",
        "lowestDesiredGranularityProductLevel": "",
        "lowestDesiredGranularityCustomer": "",
        "goldKpis": "Yes",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3219,
        "kpiName": "SFA - Sales Forecast Accuracy",
        "businessDefinition": "Determines accuracy of demand forecast (lag 1,3) against actual sales\nWhat was sales ability to forecast the SALES we fulfilled vs FORECAST. This is currently review based on Forecast from LAG1 and LAG3 – Sales is measured on this metric",
        "function": "Supply Planning, Demand Planning",
        "businessProcess": "Measure past performance",
        "reportsLinked": "Global Food Demand Consensus (PROD)",
        "formula": "∑((Absolute (Forecast sales-Actual sales) / Actuals)",
        "aggregationRules": "Item",
        "unitOfAggregation": "Cases (Claire)",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "Plant",
        "lowestDesiredGranularityTimePeriod": "Period",
        "lowestDesiredGranularityProductLevel": "Material level",
        "lowestDesiredGranularityCustomer": "National",
        "goldKpis": "Yes",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3220,
        "kpiName": "Projected Production",
        "businessDefinition": "Expected units of finished goods",
        "function": "Supply Planning, Demand Planning",
        "businessProcess": "Product Supply Plan",
        "reportsLinked": "Forescast vs capacity RTH, Production Volumes Input for SCMR",
        "formula": "∑Production-Proj",
        "aggregationRules": "Item",
        "unitOfAggregation": "Cases",
        "synonyms": "Production volume projection",
        "lowestDesiredGranularityGeography": "Production Line",
        "lowestDesiredGranularityTimePeriod": "Daily",
        "lowestDesiredGranularityProductLevel": "Material level",
        "lowestDesiredGranularityCustomer": "National",
        "goldKpis": "Yes",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3221,
        "kpiName": "Customer Service Level (CSL) Actuals",
        "businessDefinition": "Shipped cases as a percentage of ordered cases",
        "function": "CS&L",
        "businessProcess": "Measure past performance",
        "reportsLinked": "Customer Case Fill Dashboard 2.0",
        "formula": "∑Shipped Cases / ∑Ordered Cases",
        "aggregationRules": "Item",
        "unitOfAggregation": "Cases (MD)",
        "synonyms": "Case Fill rate %",
        "lowestDesiredGranularityGeography": "Plant/DC level",
        "lowestDesiredGranularityTimePeriod": "Weekly",
        "lowestDesiredGranularityProductLevel": "Material level",
        "lowestDesiredGranularityCustomer": "Customer level",
        "goldKpis": "Yes",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3222,
        "kpiName": "Items on Allocation",
        "businessDefinition": "Upper limit of the no. of cases/pallets that can be ordered by a customer (in case of products which need prioritising within customers)",
        "function": "CS&L",
        "businessProcess": "S&OE",
        "reportsLinked": "Daily Allocation & Quota Report",
        "formula": "N/A",
        "aggregationRules": "",
        "unitOfAggregation": "",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "Plant level",
        "lowestDesiredGranularityTimePeriod": "Weekly",
        "lowestDesiredGranularityProductLevel": "Material level",
        "lowestDesiredGranularityCustomer": "Customer level",
        "goldKpis": "No",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3223,
        "kpiName": "Fines",
        "businessDefinition": "penalty imposed as a punishment for non compliance of material  delivery",
        "function": "CS&L",
        "businessProcess": "Measure past performance",
        "reportsLinked": "Fines & Fees Report",
        "formula": "No",
        "aggregationRules": "NA",
        "unitOfAggregation": "USD",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "N/A",
        "lowestDesiredGranularityTimePeriod": "Period level",
        "lowestDesiredGranularityProductLevel": "N/A",
        "lowestDesiredGranularityCustomer": "Customer level",
        "goldKpis": "No",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3224,
        "kpiName": "Fees",
        "businessDefinition": "are charges levied for specific services or privileges",
        "function": "CS&L",
        "businessProcess": "Measure past performance",
        "reportsLinked": "Fines & Fees Report",
        "formula": "No",
        "aggregationRules": "NA",
        "unitOfAggregation": "USD",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "N/A",
        "lowestDesiredGranularityTimePeriod": "Period level",
        "lowestDesiredGranularityProductLevel": "N/A",
        "lowestDesiredGranularityCustomer": "Customer level",
        "goldKpis": "No",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3225,
        "kpiName": "Overage",
        "businessDefinition": "refer to situations where the quantity or weight of goods received exceeds the expected or ordered amount",
        "function": "CS&L",
        "businessProcess": "Measure past performance",
        "reportsLinked": "Fines & Fees Report",
        "formula": "No",
        "aggregationRules": "NA",
        "unitOfAggregation": "USD",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "N/A",
        "lowestDesiredGranularityTimePeriod": "Period level",
        "lowestDesiredGranularityProductLevel": "Material",
        "lowestDesiredGranularityCustomer": "Customer level",
        "goldKpis": "No",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3226,
        "kpiName": "Shortage",
        "businessDefinition": "occurs when the actual quantity of goods received falls short of what was expected or contracted for.",
        "function": "CS&L",
        "businessProcess": "Measure past performance",
        "reportsLinked": "Fines & Fees Report",
        "formula": "No",
        "aggregationRules": "NA",
        "unitOfAggregation": "USD",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "N/A",
        "lowestDesiredGranularityTimePeriod": "Period level",
        "lowestDesiredGranularityProductLevel": "Material",
        "lowestDesiredGranularityCustomer": "Customer level",
        "goldKpis": "No",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3227,
        "kpiName": "Amount accrued",
        "businessDefinition": "total expenses related to freight that have been incurred by a company but not yet paid",
        "function": "CS&L",
        "businessProcess": "Measure past performance",
        "reportsLinked": "Paid & Accrued Tracking Report",
        "formula": "No",
        "aggregationRules": "NA",
        "unitOfAggregation": "USD",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "Del location",
        "lowestDesiredGranularityTimePeriod": "Period level",
        "lowestDesiredGranularityProductLevel": "N/A",
        "lowestDesiredGranularityCustomer": "N/A",
        "goldKpis": "No",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3228,
        "kpiName": "Amount paid",
        "businessDefinition": "total cost incurred by a company for transporting goods from one location to another",
        "function": "CS&L",
        "businessProcess": "Measure past performance",
        "reportsLinked": "Paid & Accrued Tracking Report",
        "formula": "No",
        "aggregationRules": "NA",
        "unitOfAggregation": "USD",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "Del location",
        "lowestDesiredGranularityTimePeriod": "Period level",
        "lowestDesiredGranularityProductLevel": "N/A",
        "lowestDesiredGranularityCustomer": "N/A",
        "goldKpis": "No",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3229,
        "kpiName": "OT - Overall",
        "businessDefinition": "refers to the overall deliveries that are made on time across all types of shipments, including both Full Truckload (FTL) and Less Than Truckload (LTL) shipments",
        "function": "CS&L",
        "businessProcess": "Measure past performance",
        "reportsLinked": "Transportation KPIs",
        "formula": "Number of On-Time Deliveries / Total Number of Deliveries x 100",
        "aggregationRules": "",
        "unitOfAggregation": "",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "Region",
        "lowestDesiredGranularityTimePeriod": "Period level",
        "lowestDesiredGranularityProductLevel": "N/A",
        "lowestDesiredGranularityCustomer": "N/A",
        "goldKpis": "No",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3230,
        "kpiName": "OT - LTL",
        "businessDefinition": "less than truck load shipments that are delivered within the specified timeframe .",
        "function": "CS&L",
        "businessProcess": "Measure past performance",
        "reportsLinked": "Transportation KPIs",
        "formula": "Number of On-Time LTL Deliveries / Total Number of LTL Deliveries x 100",
        "aggregationRules": "",
        "unitOfAggregation": "",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "Region",
        "lowestDesiredGranularityTimePeriod": "Period level",
        "lowestDesiredGranularityProductLevel": "N/A",
        "lowestDesiredGranularityCustomer": "N/A",
        "goldKpis": "No",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3231,
        "kpiName": "OT - FTL",
        "businessDefinition": "Full Truckload shipments that are delivered within the specified timeframe .",
        "function": "CS&L",
        "businessProcess": "Measure past performance",
        "reportsLinked": "Transportation KPIs",
        "formula": "Number of On-Time FTL Deliveries / Total Number of FTL Deliveries x 100",
        "aggregationRules": "",
        "unitOfAggregation": "",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "Region",
        "lowestDesiredGranularityTimePeriod": "Period level",
        "lowestDesiredGranularityProductLevel": "N/A",
        "lowestDesiredGranularityCustomer": "N/A",
        "goldKpis": "No",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3232,
        "kpiName": "DOS - Days of Supply",
        "businessDefinition": "How long inventory on hand will last. This measure projects the amount of inventory (stock) expressed in days of sales",
        "function": "CS&L, Supply Planning",
        "businessProcess": "S&OE",
        "reportsLinked": "Inventory by DC Report, Warehouse Inventory Projections 12.18.23 part 2",
        "formula": "∑Inventory  / average proj daily sales (as per 7 Days)",
        "aggregationRules": "Item",
        "unitOfAggregation": "Cases, GSV, COGS (OZGE)",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "Plant level",
        "lowestDesiredGranularityTimePeriod": "Periodically",
        "lowestDesiredGranularityProductLevel": "Material level",
        "lowestDesiredGranularityCustomer": "Customer level",
        "goldKpis": "Yes",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3233,
        "kpiName": "Inventory - Cases (Base UOM)",
        "businessDefinition": "Inventory refers to the finished goods stored inside a warehouse prior to production, shipping or selling.",
        "function": "CS&L, Supply Planning",
        "businessProcess": "Inventory Health Management",
        "reportsLinked": "Inventory by DC Report",
        "formula": "∑Inventory",
        "aggregationRules": "",
        "unitOfAggregation": "Cases",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "Plant level",
        "lowestDesiredGranularityTimePeriod": "Weekly",
        "lowestDesiredGranularityProductLevel": "Material level",
        "lowestDesiredGranularityCustomer": "Customer level",
        "goldKpis": "Yes",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3234,
        "kpiName": "Inventory - $",
        "businessDefinition": "Inventory refers to the finished goods stored inside a warehouse prior to production, shipping or selling.",
        "function": "CS&L, Supply Planning",
        "businessProcess": "Inventory Health Management",
        "reportsLinked": "Inventory Quality, Inventory by DC",
        "formula": "∑Inventory",
        "aggregationRules": "",
        "unitOfAggregation": "GSV & Cogs",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "Plant level",
        "lowestDesiredGranularityTimePeriod": "Weekly",
        "lowestDesiredGranularityProductLevel": "Material level",
        "lowestDesiredGranularityCustomer": "Customer level",
        "goldKpis": "Yes",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3235,
        "kpiName": "Inventory Projections  (cases, kg, $, Pallets)",
        "businessDefinition": "Future inventory value ($, GSV, cases) – by Sku/DC/Warehouse/Plant for certain no. of periods",
        "function": "CS&L, Supply Planning",
        "businessProcess": "Inventory Health Management",
        "reportsLinked": "Warehouse Inventory Projections 12.18.23 part 2",
        "formula": "∑Inventory-Proj",
        "aggregationRules": "Item",
        "unitOfAggregation": "Cases, Kgs, GSV, Pallets",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "Plant level",
        "lowestDesiredGranularityTimePeriod": "Weekly",
        "lowestDesiredGranularityProductLevel": "Material level",
        "lowestDesiredGranularityCustomer": "Customer level",
        "goldKpis": "Yes",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3236,
        "kpiName": "Inventory health",
        "businessDefinition": "Provides percentage detail of inventory that is in SLOMO (not fit for use)",
        "function": "Supply Planning",
        "businessProcess": "Supply Planning",
        "reportsLinked": "Inventory Quality Dashboard",
        "formula": "SLOMO Inventory / Total inventory",
        "aggregationRules": "",
        "unitOfAggregation": "",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "Plant/DC level",
        "lowestDesiredGranularityTimePeriod": "Weekly",
        "lowestDesiredGranularityProductLevel": "Material level",
        "lowestDesiredGranularityCustomer": "National",
        "goldKpis": "Yes",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3237,
        "kpiName": "SLOMO Inventory",
        "businessDefinition": "Slow moving inventory is defined as stock keeping units (SKUs) that have not shipped in a certain amount of time, such as 90 or 180 days, and merchandise that has a low turn rate relative to the quantity on hand.",
        "function": "CS&L, Supply Planning",
        "businessProcess": "Inventory Health Management",
        "reportsLinked": "FOOD SLOMO BI",
        "formula": "SLOMO Inventory = destroy  (<1 month)+ donate (1-3months) + liquidate (3-6 months) + fastrack (> 6 months)",
        "aggregationRules": "Item",
        "unitOfAggregation": "Cases",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "Plant level",
        "lowestDesiredGranularityTimePeriod": "Weekly",
        "lowestDesiredGranularityProductLevel": "Material level",
        "lowestDesiredGranularityCustomer": "Customer level",
        "goldKpis": "No",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3238,
        "kpiName": "Aging inventory",
        "businessDefinition": "Aging inventory is a term for goods that haven’t sold quickly",
        "function": "CS&L, Supply Planning",
        "businessProcess": "Inventory Health Management",
        "reportsLinked": "Inventory Quality Dashboard",
        "formula": "$ value or units of inventory falling in different shelf life buckets",
        "aggregationRules": "Item",
        "unitOfAggregation": "COGS or Cases",
        "synonyms": "SLOMO",
        "lowestDesiredGranularityGeography": "Plant level",
        "lowestDesiredGranularityTimePeriod": "Weekly",
        "lowestDesiredGranularityProductLevel": "Material level",
        "lowestDesiredGranularityCustomer": "Customer level",
        "goldKpis": "No",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3239,
        "kpiName": "Excess Inventory",
        "businessDefinition": "Excess inventory refers to the stock that reached the end of its product life cycle and could not be sold. Considering that the inventory is excess also assumes that you do not expect to sell it in the foreseeable future. ",
        "function": "CS&L, Supply Planning",
        "businessProcess": "Inventory Health Management",
        "reportsLinked": "Inventory Quality Dashboard",
        "formula": "Excess Inventory = If Shelf Life Remaning is > 90 and <=252",
        "aggregationRules": "Item",
        "unitOfAggregation": "COGS or Cases",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "Plant level",
        "lowestDesiredGranularityTimePeriod": "Weekly",
        "lowestDesiredGranularityProductLevel": "Material level",
        "lowestDesiredGranularityCustomer": "Customer level",
        "goldKpis": "No",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3240,
        "kpiName": "Stockouts OOS / Out of Stock risk",
        "businessDefinition": "The exposure to loss that arises from running out of one or more items of inventory.",
        "function": "Supply Planning",
        "businessProcess": "Manage Inventory Risk",
        "reportsLinked": "Ben's Original Risks - NA, RTH Stock level pivot",
        "formula": "∑Projected Inventory - ∑Demand (where demand > projected inventory) for specific time interval (12 weeks, 26 weeks, 52 weeks)",
        "aggregationRules": "Item",
        "unitOfAggregation": "Cases, GSV",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "Plant",
        "lowestDesiredGranularityTimePeriod": "Weekly",
        "lowestDesiredGranularityProductLevel": "SKU",
        "lowestDesiredGranularityCustomer": "National",
        "goldKpis": "No",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3241,
        "kpiName": "OOS%",
        "businessDefinition": "Provides percentage detail of OOS ",
        "function": "Supply Planning",
        "businessProcess": "Manage Inventory Risk",
        "reportsLinked": "Ben's Original Risks - NA, RTH Stock level pivot",
        "formula": "Total OOS/Average Demand​",
        "aggregationRules": "",
        "unitOfAggregation": "",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "Plant",
        "lowestDesiredGranularityTimePeriod": "Weekly",
        "lowestDesiredGranularityProductLevel": "SKU",
        "lowestDesiredGranularityCustomer": "National",
        "goldKpis": "No",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3242,
        "kpiName": "Projected OOS date",
        "businessDefinition": "The anticipated date when inventory levels will be finished based on current demand and supply parameters",
        "function": "Supply Planning",
        "businessProcess": "Inventory",
        "reportsLinked": "FOOD_2_22_2024",
        "formula": " Current Date + (Available Inventory / Average Daily Sales)",
        "aggregationRules": "Item",
        "unitOfAggregation": "Cases, GSV, COGS",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "",
        "lowestDesiredGranularityTimePeriod": "",
        "lowestDesiredGranularityProductLevel": "",
        "lowestDesiredGranularityCustomer": "",
        "goldKpis": "No",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3243,
        "kpiName": "Average Daily Sales",
        "businessDefinition": "Average Projected Sales",
        "function": "Supply Planning",
        "businessProcess": "Inventory",
        "reportsLinked": "TBD",
        "formula": "∑Projected Sales/Number of # days",
        "aggregationRules": "Item",
        "unitOfAggregation": "Cases, GSV, COGS",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "",
        "lowestDesiredGranularityTimePeriod": "",
        "lowestDesiredGranularityProductLevel": "",
        "lowestDesiredGranularityCustomer": "",
        "goldKpis": "No",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3244,
        "kpiName": "Bias",
        "businessDefinition": "Indicates a tendency to either over-forecast (forecast is more than the actual), or under-forecast (forecast is less than the actual), leading to a forecasting error",
        "function": "Demand Planning",
        "businessProcess": "Measure past performance",
        "reportsLinked": "Global Food Demand Consensus (PROD)",
        "formula": "(∑[Forecasted Sales] - ∑[Actual sales])/∑[Actual Sales]",
        "aggregationRules": "demand unit",
        "unitOfAggregation": "GSV, Cases & Tonnage",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "Production Line",
        "lowestDesiredGranularityTimePeriod": "Period level",
        "lowestDesiredGranularityProductLevel": "Demand unit",
        "lowestDesiredGranularityCustomer": "N/A",
        "goldKpis": "Yes",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3245,
        "kpiName": "$ Sales (Actuals)",
        "businessDefinition": "Actual sales achieved by a company within a period",
        "function": "Demand Planning",
        "businessProcess": "Revenue",
        "reportsLinked": "TBD",
        "formula": "∑qty sold * selling price",
        "aggregationRules": "Item",
        "unitOfAggregation": "GSV, Cases & Tonnage",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "Production Line",
        "lowestDesiredGranularityTimePeriod": "Period level",
        "lowestDesiredGranularityProductLevel": "Demand unit",
        "lowestDesiredGranularityCustomer": "Demand group",
        "goldKpis": "No",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3246,
        "kpiName": "$ Sales (Forecast)",
        "businessDefinition": "Sales projections or estimates made by the company for a particular period",
        "function": "Demand Planning",
        "businessProcess": "Projected Revenue",
        "reportsLinked": "TBD",
        "formula": "No",
        "aggregationRules": "",
        "unitOfAggregation": "GSV, Cases & Tonnage",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "Production Line",
        "lowestDesiredGranularityTimePeriod": "Period level",
        "lowestDesiredGranularityProductLevel": "Demand unit",
        "lowestDesiredGranularityCustomer": "Demand group",
        "goldKpis": "No",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3247,
        "kpiName": "SFA to order",
        "businessDefinition": "Accuracy of sales forecasts in predicting the actual demand for a product , with respect to quantity of orders placed",
        "function": "Demand Planning",
        "businessProcess": "Inventory",
        "reportsLinked": "SFA & Bias dashboard",
        "formula": "1 – [Absolute value of (∑Actual Orders Placed – ∑Forecast sales) / ∑Actual Orders Placed] for same time period",
        "aggregationRules": "Item",
        "unitOfAggregation": "GSV, Cases & Tonnage",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "Production Line",
        "lowestDesiredGranularityTimePeriod": "Period level",
        "lowestDesiredGranularityProductLevel": "Material",
        "lowestDesiredGranularityCustomer": "Demand group",
        "goldKpis": "Yes",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3248,
        "kpiName": "SFA to shipment",
        "businessDefinition": "Accuracy of sales forecasts in predicting the actual demand for a product , with respect to quantity of orders shipped",
        "function": "Demand Planning",
        "businessProcess": "Inventory",
        "reportsLinked": "SFA & Bias dashboard",
        "formula": "1 – [Absolute value of (∑Actual Orders Shipped – ∑Forecast sales) / ∑Actual Orders Shipped] for same time period",
        "aggregationRules": "Item",
        "unitOfAggregation": "GSV, Cases & Tonnage",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "Production Line",
        "lowestDesiredGranularityTimePeriod": "Period level",
        "lowestDesiredGranularityProductLevel": "Material",
        "lowestDesiredGranularityCustomer": "Demand group",
        "goldKpis": "Yes",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3249,
        "kpiName": "Bias to order",
        "businessDefinition": "Any  deviation or tendency in sales forecasting that consistently overestimates or underestimates the quantity of orders needed for a product with respect to orders placed",
        "function": "Demand Planning",
        "businessProcess": "Measure past performance",
        "reportsLinked": "SFA & Bias dashboard",
        "formula": "(∑[Forecasted Sales] - ∑[Orders placed])/∑[Forecasted Sales]",
        "aggregationRules": "Item",
        "unitOfAggregation": "GSV, Cases & Tonnage",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "Production Line",
        "lowestDesiredGranularityTimePeriod": "Period level",
        "lowestDesiredGranularityProductLevel": "Material",
        "lowestDesiredGranularityCustomer": "Demand group",
        "goldKpis": "Yes",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3250,
        "kpiName": "Bias to shipment",
        "businessDefinition": "Any  deviation or tendency in sales forecasting that consistently overestimates or underestimates the quantity of orders needed for a product with respect to orders shipped to customer",
        "function": "Demand Planning",
        "businessProcess": "Measure past performance",
        "reportsLinked": "SFA & Bias dashboard",
        "formula": "(∑[Forecasted Sales] - ∑[Orders shipped])/∑[Forecasted Sales]",
        "aggregationRules": "Item",
        "unitOfAggregation": "GSV, Cases & Tonnage",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "Production Line",
        "lowestDesiredGranularityTimePeriod": "Period level",
        "lowestDesiredGranularityProductLevel": "Material",
        "lowestDesiredGranularityCustomer": "Demand group",
        "goldKpis": "Yes",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3251,
        "kpiName": "GSV Forecast",
        "businessDefinition": "Projected demand for a product in terms of Gross Sales Value",
        "function": "Demand Planning",
        "businessProcess": "Projected Revenue",
        "reportsLinked": "TBD",
        "formula": "∑Forecasted demand qty * selling price",
        "aggregationRules": "Item",
        "unitOfAggregation": "GSV, Cases & Tonnage",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "N/A",
        "lowestDesiredGranularityTimePeriod": "Period level",
        "lowestDesiredGranularityProductLevel": "Material",
        "lowestDesiredGranularityCustomer": "Demand Unit",
        "goldKpis": "No",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3252,
        "kpiName": "GSV Replan ",
        "businessDefinition": "Revised demand forecast in terms of Gross Sales Value based on changes in market conditions, customer demand or other relevant factors",
        "function": "Demand Planning",
        "businessProcess": "Projected Revenue",
        "reportsLinked": "2024 (Cycle 1) Total Food GSV roll-up Final",
        "formula": "∑updated forecasted qty   * selling price",
        "aggregationRules": "Item",
        "unitOfAggregation": "GSV, Cases & Tonnage",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "N/A",
        "lowestDesiredGranularityTimePeriod": "Period level",
        "lowestDesiredGranularityProductLevel": "Material",
        "lowestDesiredGranularityCustomer": "Demand Unit",
        "goldKpis": "No",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3253,
        "kpiName": "GSV Unconstrained ",
        "businessDefinition": "Estimate of the total potential revenue that could be generated under ideal conditions, assuming no constraints on production or distribution.",
        "function": "Demand Planning",
        "businessProcess": "Projected Revenue (unconstrained)",
        "reportsLinked": "2024 (Cycle 1) Total Food GSV roll-up Final",
        "formula": "∑demand * selling price",
        "aggregationRules": "Item",
        "unitOfAggregation": "GSV, Cases & Tonnage",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "N/A",
        "lowestDesiredGranularityTimePeriod": "Period level",
        "lowestDesiredGranularityProductLevel": "Material",
        "lowestDesiredGranularityCustomer": "Demand Unit",
        "goldKpis": "No",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3254,
        "kpiName": "GSV OP (operating plan)",
        "businessDefinition": "Sales plan (in terms of demand projections) that outlines the expected sales revenue generated from the core operations of a business over a specific period",
        "function": "Demand Planning",
        "businessProcess": "Projected Revenue",
        "reportsLinked": "2024 (Cycle 1) Total Food GSV roll-up Final",
        "formula": "GSV inital * adjustment factor",
        "aggregationRules": "Item",
        "unitOfAggregation": "GSV, Cases & Tonnage",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "N/A",
        "lowestDesiredGranularityTimePeriod": "Period level",
        "lowestDesiredGranularityProductLevel": "Material",
        "lowestDesiredGranularityCustomer": "Demand Unit",
        "goldKpis": "No",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3255,
        "kpiName": "GSV Financial (Rev Call)",
        "businessDefinition": "Projected Sales targets (in terms of GSV) defined for a period based on latest demand estimates",
        "function": "Demand Planning",
        "businessProcess": "Projected Revenue",
        "reportsLinked": "2024 (Cycle 1) Total Food GSV roll-up Final",
        "formula": "∑qty forecasted * selling price",
        "aggregationRules": "Item",
        "unitOfAggregation": "GSV, Cases & Tonnage",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "N/A",
        "lowestDesiredGranularityTimePeriod": "Period level",
        "lowestDesiredGranularityProductLevel": "Material",
        "lowestDesiredGranularityCustomer": "Demand Unit",
        "goldKpis": "No",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3256,
        "kpiName": "Risk adjusted GSV",
        "businessDefinition": "Forecasted Sales (in terms of GSV) adjusted to account for various risks and uncertainties that may impact the actual realization of sales revenue",
        "function": "Demand Planning",
        "businessProcess": "Projected Revenue (Constrained)",
        "reportsLinked": "2024 (Cycle 1) Total Food GSV roll-up Final",
        "formula": "base GSV * (1 - Risk factor)",
        "aggregationRules": "Item",
        "unitOfAggregation": "GSV, Cases & Tonnage",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "Production location",
        "lowestDesiredGranularityTimePeriod": "Quarter",
        "lowestDesiredGranularityProductLevel": "Category",
        "lowestDesiredGranularityCustomer": "Customer level",
        "goldKpis": "No",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3257,
        "kpiName": "NSV Financial vs PY NSV",
        "businessDefinition": "refers to the comparison between the NSV in the current financial period and the NSV in the previous year (PY).",
        "function": "Demand Planning",
        "businessProcess": "Measure past performance",
        "reportsLinked": "2024 (Cycle 1) Total Food GSV roll-up Final",
        "formula": "",
        "aggregationRules": "Item",
        "unitOfAggregation": "#N/A",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "#N/A",
        "lowestDesiredGranularityTimePeriod": "#N/A",
        "lowestDesiredGranularityProductLevel": "#N/A",
        "lowestDesiredGranularityCustomer": "#N/A",
        "goldKpis": "No",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3258,
        "kpiName": "",
        "businessDefinition": "",
        "function": "",
        "businessProcess": "",
        "reportsLinked": "",
        "formula": "",
        "aggregationRules": "",
        "unitOfAggregation": "",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "",
        "lowestDesiredGranularityTimePeriod": "",
        "lowestDesiredGranularityProductLevel": "",
        "lowestDesiredGranularityCustomer": "",
        "goldKpis": "",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3259,
        "kpiName": "Shipment Cases - projection",
        "businessDefinition": "# of cases shipped (projected) for a given material in an invoice",
        "function": "CS&L",
        "businessProcess": "Measure past performance",
        "reportsLinked": "Customer Case Fill Dashboard 2.0",
        "formula": "∑Shipped Cases- Proj",
        "aggregationRules": "Item",
        "unitOfAggregation": "Cases, GSV",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "Plant/DC level",
        "lowestDesiredGranularityTimePeriod": "Weekly",
        "lowestDesiredGranularityProductLevel": "Material level",
        "lowestDesiredGranularityCustomer": "Customer level",
        "goldKpis": "No",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3260,
        "kpiName": "Order vs Shipment (GSV)",
        "businessDefinition": "DIfference of shipped versus ordered cases  ($ value)",
        "function": "CS&L",
        "businessProcess": "Measure past performance",
        "reportsLinked": "Customer Case Fill Dashboard 2.0",
        "formula": "∑Shipped-∑Ordered cases",
        "aggregationRules": "Item",
        "unitOfAggregation": "GSV (USD)",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "Plant level",
        "lowestDesiredGranularityTimePeriod": "Daily",
        "lowestDesiredGranularityProductLevel": "Material level",
        "lowestDesiredGranularityCustomer": "Customer level",
        "goldKpis": "No",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3261,
        "kpiName": "Order vs Shipment (Cases)",
        "businessDefinition": "DIfference of shipped versus ordered cases - numerical",
        "function": "CS&L",
        "businessProcess": "Measure past performance",
        "reportsLinked": "Customer Case Fill Dashboard 2.0",
        "formula": "∑Shipped-∑Ordered cases",
        "aggregationRules": "Item",
        "unitOfAggregation": "Cases",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "Plant level",
        "lowestDesiredGranularityTimePeriod": "Daily",
        "lowestDesiredGranularityProductLevel": "Material level",
        "lowestDesiredGranularityCustomer": "Customer level",
        "goldKpis": "Yes",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3262,
        "kpiName": "Shipments Pacing",
        "businessDefinition": "It is the the speed with which orders are shipped / day, helps in determing, whether all the orders will be shipped or not ",
        "function": "CS&L",
        "businessProcess": "S&OE",
        "reportsLinked": "Order Bank",
        "formula": "Actual shipment x Days gone (in a period)/Official Forecast*28",
        "aggregationRules": "Item",
        "unitOfAggregation": "Cases, GSV",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "N/A",
        "lowestDesiredGranularityTimePeriod": "Daily",
        "lowestDesiredGranularityProductLevel": "Material level",
        "lowestDesiredGranularityCustomer": "Customer level",
        "goldKpis": "No",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3263,
        "kpiName": "Cut GSV",
        "businessDefinition": "Gross sales value of cases not shipped from a given order",
        "function": "CS&L",
        "businessProcess": "Measure past performance",
        "reportsLinked": "Customer Case Fill Dashboard 2.0",
        "formula": "∑Ordered GSV -∑ Shipped GSV",
        "aggregationRules": "Item",
        "unitOfAggregation": "GSV",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "Plant level",
        "lowestDesiredGranularityTimePeriod": "Daily",
        "lowestDesiredGranularityProductLevel": "Material level",
        "lowestDesiredGranularityCustomer": "Customer level",
        "goldKpis": "Yes",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3264,
        "kpiName": "Order cases - projection",
        "businessDefinition": "# of cases ordered (projected) for a given material in a sales document",
        "function": "Customer Service and Order Fulfillment",
        "businessProcess": "Measure past performance",
        "reportsLinked": "Customer Case Fill Dashboard 2.0",
        "formula": "∑Ordered Cases- Proj",
        "aggregationRules": "Item",
        "unitOfAggregation": "Cases, GSV",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "Plant/DC level",
        "lowestDesiredGranularityTimePeriod": "Weekly",
        "lowestDesiredGranularityProductLevel": "Material level",
        "lowestDesiredGranularityCustomer": "Customer level",
        "goldKpis": "No",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3265,
        "kpiName": "Items on Quota",
        "businessDefinition": "Upper limit of the no. of cases/pallets that can be ordered by a customer",
        "function": "Customer Service and Order Fulfillment",
        "businessProcess": "S&OE",
        "reportsLinked": "Daily Allocation & Quota Report",
        "formula": "∑Forecast errors over multiple periods / ∑observed periods",
        "aggregationRules": "",
        "unitOfAggregation": "",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "Plant level",
        "lowestDesiredGranularityTimePeriod": "Weekly",
        "lowestDesiredGranularityProductLevel": "Material level",
        "lowestDesiredGranularityCustomer": "Customer level",
        "goldKpis": "No",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    },
    {
        "id": 3266,
        "kpiName": "Inventory - Tonnage",
        "businessDefinition": "Inventory refers to the finished goods stored inside a warehouse prior to production, shipping or selling.",
        "function": "Supply Planning, CS&L",
        "businessProcess": "Inventory Health Management",
        "reportsLinked": "Inventory Quality, Inventory by DC",
        "formula": "∑Inventory",
        "aggregationRules": "",
        "unitOfAggregation": "Tonnage",
        "synonyms": "",
        "lowestDesiredGranularityGeography": "Plant level",
        "lowestDesiredGranularityTimePeriod": "Weekly",
        "lowestDesiredGranularityProductLevel": "Material level",
        "lowestDesiredGranularityCustomer": "Customer level",
        "goldKpis": "Yes",
        "addedBy": "harsh.kulshreshtha@effem.com",
        "kpiOwner": "",
        "createdAt": 1721671919457
    }
]
// const HomeData = [
//     {
//         "GRD": 101,
//         "Description": "Indian Probowl",
//         "BRAND_FLAG": "TASTY BITE",
//         "portionsize": 250,
//         "Regional product category": "RTH R&l",
//         "H&N Framework": "Full meal",
//         "ENERGY KCAL": 152,
//         "Fruits Content per 100g": 3,
//         "Sugars Added_per_100g": 1,
//         "Vegetables_Content per 100g": 16,
//         "Wholegrains_Content per 100g": 28,
//         "Carbohydrate_per 100g": 26,
//         "Fat_Saturates_per 100g": 0,
//         "Fat_per 100g": 3,
//         "Fibre_Dietary_Total_AOAC per 100g": 6,
//         "Protein per 100g": 5,
//         "Sodium per 100g": 184,
//         "Sugars_(Mono&Di-saccharides)_per 100g": 3,
//         "Trans_Fatty_Acids_per 100g": 0
//     },
//     {
//         "GRD": 102,
//         "Description": "Mexican Probowl",
//         "BRAND_FLAG": "TASTY BITE",
//         "portionsize": 250,
//         "Regional product category": "Bowl",
//         "H&N Framework": "Full meal",
//         "ENERGY KCAL": 144,
//         "Fruits Content per 100g": 0,
//         "Sugars Added_per_100g": 1,
//         "Vegetables_Content per 100g": 22,
//         "Wholegrains_Content per 100g": 25,
//         "Carbohydrate_per 100g": 24,
//         "Fat_Saturates_per 100g": 0,
//         "Fat_per 100g": 3,
//         "Fibre_Dietary_Total_AOAC per 100g": 7,
//         "Protein per 100g": 5,
//         "Sodium per 100g": 208,
//         "Sugars_(Mono&Di-saccharides)_per 100g": 2,
//         "Trans_Fatty_Acids_per 100g": 0
//     },
//     {
//         "GRD": 103,
//         "Description": "Mediterranean Probowl",
//         "BRAND_FLAG": "TASTY BITE",
//         "portionsize": 250,
//         "Regional product category": "Bowl",
//         "H&N Framework": "Full meal",
//         "ENERGY KCAL": 164,
//         "Fruits Content per 100g": 0,
//         "Sugars Added_per_100g": 1,
//         "Vegetables_Content per 100g": 35,
//         "Wholegrains_Content per 100g": 11,
//         "Carbohydrate_per 100g": 26,
//         "Fat_Saturates_per 100g": 0,
//         "Fat_per 100g": 4,
//         "Fibre_Dietary_Total_AOAC per 100g": 6,
//         "Protein per 100g": 6,
//         "Sodium per 100g": 180,
//         "Sugars_(Mono&Di-saccharides)_per 100g": 3,
//         "Trans_Fatty_Acids_per 100g": 0
//     },
//     {
//         "GRD": 104,
//         "Description": "Citrus Cilantro Protein Bowl",
//         "BRAND_FLAG": "TASTY BITE",
//         "portionsize": 250,
//         "Regional product category": "Bowl",
//         "H&N Framework": "Full meal",
//         "ENERGY KCAL": 144,
//         "Fruits Content per 100g": 0,
//         "Sugars Added_per_100g": 1,
//         "Vegetables_Content per 100g": 22,
//         "Wholegrains_Content per 100g": 25,
//         "Carbohydrate_per 100g": 24,
//         "Fat_Saturates_per 100g": 0,
//         "Fat_per 100g": 3,
//         "Fibre_Dietary_Total_AOAC per 100g": 7,
//         "Protein per 100g": 5,
//         "Sodium per 100g": 208,
//         "Sugars_(Mono&Di-saccharides)_per 100g": 2,
//         "Trans_Fatty_Acids_per 100g": 0
//     },
//     {
//         "GRD": 105,
//         "Description": "Curry & Rice Bowl",
//         "BRAND_FLAG": "TASTY BITE",
//         "portionsize": 250,
//         "Regional product category": "Bowl",
//         "H&N Framework": "Full meal",
//         "ENERGY KCAL": 132,
//         "Fruits Content per 100g": 0,
//         "Sugars Added_per_100g": 0,
//         "Vegetables_Content per 100g": 44,
//         "Wholegrains_Content per 100g": 0,
//         "Carbohydrate_per 100g": 22,
//         "Fat_Saturates_per 100g": 2,
//         "Fat_per 100g": 4,
//         "Fibre_Dietary_Total_AOAC per 100g": 2,
//         "Protein per 100g": 3,
//         "Sodium per 100g": 236,
//         "Sugars_(Mono&Di-saccharides)_per 100g": 1,
//         "Trans_Fatty_Acids_per 100g": 0
//     },
//     {
//         "GRD": 106,
//         "Description": "Protein Bowl Chickpea Masala",
//         "BRAND_FLAG": "TASTY BITE",
//         "portionsize": 250,
//         "Regional product category": "Bowl",
//         "H&N Framework": "Full meal",
//         "ENERGY KCAL": 152,
//         "Fruits Content per 100g": 0,
//         "Sugars Added_per_100g": 0,
//         "Vegetables_Content per 100g": 24,
//         "Wholegrains_Content per 100g": 19,
//         "Carbohydrate_per 100g": 25,
//         "Fat_Saturates_per 100g": 0,
//         "Fat_per 100g": 4,
//         "Fibre_Dietary_Total_AOAC per 100g": 4,
//         "Protein per 100g": 4,
//         "Sodium per 100g": 164,
//         "Sugars_(Mono&Di-saccharides)_per 100g": 1,
//         "Trans_Fatty_Acids_per 100g": 0
//     },
//     {
//         "GRD": 107,
//         "Description": "Protein Bowl Madras Lentil",
//         "BRAND_FLAG": "TASTY BITE",
//         "portionsize": 250,
//         "Regional product category": "Bowl",
//         "H&N Framework": "Full meal",
//         "ENERGY KCAL": 136,
//         "Fruits Content per 100g": 0,
//         "Sugars Added_per_100g": 0,
//         "Vegetables_Content per 100g": 5,
//         "Wholegrains_Content per 100g": 29,
//         "Carbohydrate_per 100g": 24,
//         "Fat_Saturates_per 100g": 1,
//         "Fat_per 100g": 3,
//         "Fibre_Dietary_Total_AOAC per 100g": 4,
//         "Protein per 100g": 4,
//         "Sodium per 100g": 224,
//         "Sugars_(Mono&Di-saccharides)_per 100g": 0,
//         "Trans_Fatty_Acids_per 100g": 0
//     },
//     {
//         "GRD": 108,
//         "Description": "Rice Bowl Chickpea Biryani",
//         "BRAND_FLAG": "TASTY BITE",
//         "portionsize": 200,
//         "Regional product category": "Bowl",
//         "H&N Framework": "Full meal",
//         "ENERGY KCAL": 190,
//         "Fruits Content per 100g": 0,
//         "Sugars Added_per_100g": 1,
//         "Vegetables_Content per 100g": 18,
//         "Wholegrains_Content per 100g": 26,
//         "Carbohydrate_per 100g": 30,
//         "Fat_Saturates_per 100g": 2,
//         "Fat_per 100g": 6,
//         "Fibre_Dietary_Total_AOAC per 100g": 3,
//         "Protein per 100g": 5,
//         "Sodium per 100g": 365,
//         "Sugars_(Mono&Di-saccharides)_per 100g": 2,
//         "Trans_Fatty_Acids_per 100g": 0
//     },
//     {
//         "GRD": 109,
//         "Description": "Rice Bowl Smoky Paneer Biryani",
//         "BRAND_FLAG": "TASTY BITE",
//         "portionsize": 200,
//         "Regional product category": "Bowl",
//         "H&N Framework": "Full meal",
//         "ENERGY KCAL": 145,
//         "Fruits Content per 100g": 0,
//         "Sugars Added_per_100g": 1,
//         "Vegetables_Content per 100g": 15,
//         "Wholegrains_Content per 100g": 31,
//         "Carbohydrate_per 100g": 27,
//         "Fat_Saturates_per 100g": 1,
//         "Fat_per 100g": 3,
//         "Fibre_Dietary_Total_AOAC per 100g": 1,
//         "Protein per 100g": 4,
//         "Sodium per 100g": 325,
//         "Sugars_(Mono&Di-saccharides)_per 100g": 2,
//         "Trans_Fatty_Acids_per 100g": 0
//     },
//     {
//         "GRD": 110,
//         "Description": "Rice Bowl Vegetable Biryani",
//         "BRAND_FLAG": "TASTY BITE",
//         "portionsize": 200,
//         "Regional product category": "Bowl",
//         "H&N Framework": "Full meal",
//         "ENERGY KCAL": 165,
//         "Fruits Content per 100g": 0,
//         "Sugars Added_per_100g": 0,
//         "Vegetables_Content per 100g": 27,
//         "Wholegrains_Content per 100g": 24,
//         "Carbohydrate_per 100g": 26,
//         "Fat_Saturates_per 100g": 2,
//         "Fat_per 100g": 5,
//         "Fibre_Dietary_Total_AOAC per 100g": 1,
//         "Protein per 100g": 4,
//         "Sodium per 100g": 275,
//         "Sugars_(Mono&Di-saccharides)_per 100g": 2,
//         "Trans_Fatty_Acids_per 100g": 0
//     },
//     {
//         "GRD": 111,
//         "Description": "Bombay Potato",
//         "BRAND_FLAG": "TASTY BITE",
//         "portionsize": 140,
//         "Regional product category": "RTH R&L",
//         "H&N Framework": "flavoured carb",
//         "ENERGY KCAL": 93,
//         "Fruits Content per 100g": 0,
//         "Sugars Added_per_100g": 0,
//         "Vegetables_Content per 100g": 20,
//         "Wholegrains_Content per 100g": 0,
//         "Carbohydrate_per 100g": 13,
//         "Fat_Saturates_per 100g": 0,
//         "Fat_per 100g": 4,
//         "Fibre_Dietary_Total_AOAC per 100g": 3,
//         "Protein per 100g": 3,
//         "Sodium per 100g": 321,
//         "Sugars_(Mono&Di-saccharides)_per 100g": 4,
//         "Trans_Fatty_Acids_per 100g": 0
//     },
//     {
//         "GRD": 112,
//         "Description": "Madras Lentil",
//         "BRAND_FLAG": "TASTY BITE",
//         "portionsize": 140,
//         "Regional product category": "RTH R&L",
//         "H&N Framework": "flavoured carb",
//         "ENERGY KCAL": 114,
//         "Fruits Content per 100g": 0,
//         "Sugars Added_per_100g": 0,
//         "Vegetables_Content per 100g": 11,
//         "Wholegrains_Content per 100g": 18,
//         "Carbohydrate_per 100g": 14,
//         "Fat_Saturates_per 100g": 3,
//         "Fat_per 100g": 4,
//         "Fibre_Dietary_Total_AOAC per 100g": 4,
//         "Protein per 100g": 5,
//         "Sodium per 100g": 371,
//         "Sugars_(Mono&Di-saccharides)_per 100g": 1,
//         "Trans_Fatty_Acids_per 100g": 0
//     },
//     {
//         "GRD": 113,
//         "Description": "Vegetable Tikka Masala",
//         "BRAND_FLAG": "TASTY BITE",
//         "portionsize": 140,
//         "Regional product category": "RTH R&L",
//         "H&N Framework": "flavoured carb",
//         "ENERGY KCAL": 107,
//         "Fruits Content per 100g": 0,
//         "Sugars Added_per_100g": 0,
//         "Vegetables_Content per 100g": 58,
//         "Wholegrains_Content per 100g": 0,
//         "Carbohydrate_per 100g": 12,
//         "Fat_Saturates_per 100g": 1,
//         "Fat_per 100g": 5,
//         "Fibre_Dietary_Total_AOAC per 100g": 4,
//         "Protein per 100g": 3,
//         "Sodium per 100g": 343,
//         "Sugars_(Mono&Di-saccharides)_per 100g": 5,
//         "Trans_Fatty_Acids_per 100g": 0
//     },
//     {
//         "GRD": 114,
//         "Description": "VEGETABLE KORMA",
//         "BRAND_FLAG": "TASTY BITE",
//         "portionsize": 140,
//         "Regional product category": "RTH R&L",
//         "H&N Framework": "flavoured carb",
//         "ENERGY KCAL": 93,
//         "Fruits Content per 100g": 0,
//         "Sugars Added_per_100g": 0,
//         "Vegetables_Content per 100g": 49,
//         "Wholegrains_Content per 100g": 0,
//         "Carbohydrate_per 100g": 9,
//         "Fat_Saturates_per 100g": 2,
//         "Fat_per 100g": 5,
//         "Fibre_Dietary_Total_AOAC per 100g": 1,
//         "Protein per 100g": 2,
//         "Sodium per 100g": 343,
//         "Sugars_(Mono&Di-saccharides)_per 100g": 2,
//         "Trans_Fatty_Acids_per 100g": 0
//     },
//     {
//         "GRD": 115,
//         "Description": "MUSHROOM MASALA",
//         "BRAND_FLAG": "TASTY BITE",
//         "portionsize": 140,
//         "Regional product category": "RTH R&L",
//         "H&N Framework": "flavoured carb",
//         "ENERGY KCAL": 71,
//         "Fruits Content per 100g": 0,
//         "Sugars Added_per_100g": 1,
//         "Vegetables_Content per 100g": 64,
//         "Wholegrains_Content per 100g": 0,
//         "Carbohydrate_per 100g": 9,
//         "Fat_Saturates_per 100g": 0,
//         "Fat_per 100g": 3,
//         "Fibre_Dietary_Total_AOAC per 100g": 2,
//         "Protein per 100g": 2,
//         "Sodium per 100g": 386,
//         "Sugars_(Mono&Di-saccharides)_per 100g": 4,
//         "Trans_Fatty_Acids_per 100g": 0
//     },
//     {
//         "GRD": 116,
//         "Description": "Spinach Dal",
//         "BRAND_FLAG": "TASTY BITE",
//         "portionsize": 140,
//         "Regional product category": "RTH R&L",
//         "H&N Framework": "flavoured carb",
//         "ENERGY KCAL": 79,
//         "Fruits Content per 100g": 0,
//         "Sugars Added_per_100g": 0,
//         "Vegetables_Content per 100g": 49,
//         "Wholegrains_Content per 100g": 9,
//         "Carbohydrate_per 100g": 9,
//         "Fat_Saturates_per 100g": 0,
//         "Fat_per 100g": 4,
//         "Fibre_Dietary_Total_AOAC per 100g": 3,
//         "Protein per 100g": 3,
//         "Sodium per 100g": 343,
//         "Sugars_(Mono&Di-saccharides)_per 100g": 2,
//         "Trans_Fatty_Acids_per 100g": 0
//     },
//     {
//         "GRD": 117,
//         "Description": "Vindaloo",
//         "BRAND_FLAG": "TASTY BITE",
//         "portionsize": 140,
//         "Regional product category": "RTH R&L",
//         "H&N Framework": "flavoured carb",
//         "ENERGY KCAL": 100,
//         "Fruits Content per 100g": 0,
//         "Sugars Added_per_100g": 1,
//         "Vegetables_Content per 100g": 48,
//         "Wholegrains_Content per 100g": 4,
//         "Carbohydrate_per 100g": 14,
//         "Fat_Saturates_per 100g": 1,
//         "Fat_per 100g": 4,
//         "Fibre_Dietary_Total_AOAC per 100g": 2,
//         "Protein per 100g": 2,
//         "Sodium per 100g": 343,
//         "Sugars_(Mono&Di-saccharides)_per 100g": 6,
//         "Trans_Fatty_Acids_per 100g": 0
//     },
//     {
//         "GRD": 118,
//         "Description": "Coconut Vegetables",
//         "BRAND_FLAG": "TASTY BITE",
//         "portionsize": 140,
//         "Regional product category": "RTH R&L",
//         "H&N Framework": "flavoured carb",
//         "ENERGY KCAL": 100,
//         "Fruits Content per 100g": 0,
//         "Sugars Added_per_100g": 0,
//         "Vegetables_Content per 100g": 31,
//         "Wholegrains_Content per 100g": 0,
//         "Carbohydrate_per 100g": 9,
//         "Fat_Saturates_per 100g": 4,
//         "Fat_per 100g": 6,
//         "Fibre_Dietary_Total_AOAC per 100g": 2,
//         "Protein per 100g": 2,
//         "Sodium per 100g": 343,
//         "Sugars_(Mono&Di-saccharides)_per 100g": 4,
//         "Trans_Fatty_Acids_per 100g": 0
//     },
//     {
//         "GRD": 119,
//         "Description": "Spinach & Paneer",
//         "BRAND_FLAG": "TASTY BITE",
//         "portionsize": 140,
//         "Regional product category": "RTH R&L",
//         "H&N Framework": "flavoured carb",
//         "ENERGY KCAL": 93,
//         "Fruits Content per 100g": 0,
//         "Sugars Added_per_100g": 0,
//         "Vegetables_Content per 100g": 61,
//         "Wholegrains_Content per 100g": 0,
//         "Carbohydrate_per 100g": 4,
//         "Fat_Saturates_per 100g": 3,
//         "Fat_per 100g": 6,
//         "Fibre_Dietary_Total_AOAC per 100g": 2,
//         "Protein per 100g": 4,
//         "Sodium per 100g": 357,
//         "Sugars_(Mono&Di-saccharides)_per 100g": 1,
//         "Trans_Fatty_Acids_per 100g": 0
//     },
//     {
//         "GRD": 120,
//         "Description": "Vegetables & Paneer",
//         "BRAND_FLAG": "TASTY BITE",
//         "portionsize": 140,
//         "Regional product category": "RTH R&L",
//         "H&N Framework": "flavoured carb",
//         "ENERGY KCAL": 129,
//         "Fruits Content per 100g": 2,
//         "Sugars Added_per_100g": 0,
//         "Vegetables_Content per 100g": 32,
//         "Wholegrains_Content per 100g": 0,
//         "Carbohydrate_per 100g": 9,
//         "Fat_Saturates_per 100g": 3,
//         "Fat_per 100g": 9,
//         "Fibre_Dietary_Total_AOAC per 100g": 2,
//         "Protein per 100g": 4,
//         "Sodium per 100g": 379,
//         "Sugars_(Mono&Di-saccharides)_per 100g": 4,
//         "Trans_Fatty_Acids_per 100g": 0
//     },
//     {
//         "GRD": 121,
//         "Description": "Sauteed Eggplant and Tomatoes",
//         "BRAND_FLAG": "TASTY BITE",
//         "portionsize": 140,
//         "Regional product category": "RTH R&L",
//         "H&N Framework": "flavoured carb",
//         "ENERGY KCAL": 71,
//         "Fruits Content per 100g": 0,
//         "Sugars Added_per_100g": 0,
//         "Vegetables_Content per 100g": 91,
//         "Wholegrains_Content per 100g": 0,
//         "Carbohydrate_per 100g": 9,
//         "Fat_Saturates_per 100g": 0,
//         "Fat_per 100g": 4,
//         "Fibre_Dietary_Total_AOAC per 100g": 1,
//         "Protein per 100g": 1,
//         "Sodium per 100g": 393,
//         "Sugars_(Mono&Di-saccharides)_per 100g": 3,
//         "Trans_Fatty_Acids_per 100g": 0
//     },
//     {
//         "GRD": 122,
//         "Description": "Punjab EggPlant",
//         "BRAND_FLAG": "TASTY BITE",
//         "portionsize": 140,
//         "Regional product category": "RTH R&L",
//         "H&N Framework": "flavoured carb",
//         "ENERGY KCAL": 71,
//         "Fruits Content per 100g": 7,
//         "Sugars Added_per_100g": 0,
//         "Vegetables_Content per 100g": 84,
//         "Wholegrains_Content per 100g": 0,
//         "Carbohydrate_per 100g": 9,
//         "Fat_Saturates_per 100g": 0,
//         "Fat_per 100g": 4,
//         "Fibre_Dietary_Total_AOAC per 100g": 1,
//         "Protein per 100g": 1,
//         "Sodium per 100g": 393,
//         "Sugars_(Mono&Di-saccharides)_per 100g": 3,
//         "Trans_Fatty_Acids_per 100g": 0
//     },
//     {
//         "GRD": 123,
//         "Description": "Sticky Rice ",
//         "BRAND_FLAG": "TASTY BITE",
//         "portionsize": 140,
//         "Regional product category": "RTH plain",
//         "H&N Framework": "unflavoured carb",
//         "ENERGY KCAL": 136,
//         "Fruits Content per 100g": 0,
//         "Sugars Added_per_100g": 0,
//         "Vegetables_Content per 100g": 0,
//         "Wholegrains_Content per 100g": 43,
//         "Carbohydrate_per 100g": 30,
//         "Fat_Saturates_per 100g": 0,
//         "Fat_per 100g": 0,
//         "Fibre_Dietary_Total_AOAC per 100g": 1,
//         "Protein per 100g": 4,
//         "Sodium per 100g": 0,
//         "Sugars_(Mono&Di-saccharides)_per 100g": 0,
//         "Trans_Fatty_Acids_per 100g": 0
//     },
//     {
//         "GRD": 124,
//         "Description": "Basmati Rice",
//         "BRAND_FLAG": "TASTY BITE",
//         "portionsize": 140,
//         "Regional product category": "RTH plain",
//         "H&N Framework": "unflavoured carb",
//         "ENERGY KCAL": 143,
//         "Fruits Content per 100g": 0,
//         "Sugars Added_per_100g": 0,
//         "Vegetables_Content per 100g": 0,
//         "Wholegrains_Content per 100g": 39,
//         "Carbohydrate_per 100g": 29,
//         "Fat_Saturates_per 100g": 0,
//         "Fat_per 100g": 1,
//         "Fibre_Dietary_Total_AOAC per 100g": 1,
//         "Protein per 100g": 4,
//         "Sodium per 100g": 4,
//         "Sugars_(Mono&Di-saccharides)_per 100g": 0,
//         "Trans_Fatty_Acids_per 100g": 0
//     },
//     {
//         "GRD": 125,
//         "Description": "Tikka Masala",
//         "BRAND_FLAG": "TASTY BITE",
//         "portionsize": 60,
//         "Regional product category": "Simmer sauce meal",
//         "H&N Framework": "meal maker",
//         "ENERGY KCAL": 167,
//         "Fruits Content per 100g": 0,
//         "Sugars Added_per_100g": 2,
//         "Vegetables_Content per 100g": 13,
//         "Wholegrains_Content per 100g": 0,
//         "Carbohydrate_per 100g": 12,
//         "Fat_Saturates_per 100g": 5,
//         "Fat_per 100g": 13,
//         "Fibre_Dietary_Total_AOAC per 100g": 2,
//         "Protein per 100g": 2,
//         "Sodium per 100g": 667,
//         "Sugars_(Mono&Di-saccharides)_per 100g": 5,
//         "Trans_Fatty_Acids_per 100g": 0
//     },
//     {
//         "GRD": 126,
//         "Description": "Butter Chicken",
//         "BRAND_FLAG": "TASTY BITE",
//         "portionsize": 60,
//         "Regional product category": "Simmer sauce meal",
//         "H&N Framework": "meal maker",
//         "ENERGY KCAL": 183,
//         "Fruits Content per 100g": 0,
//         "Sugars Added_per_100g": 2,
//         "Vegetables_Content per 100g": 26,
//         "Wholegrains_Content per 100g": 0,
//         "Carbohydrate_per 100g": 10,
//         "Fat_Saturates_per 100g": 7,
//         "Fat_per 100g": 15,
//         "Fibre_Dietary_Total_AOAC per 100g": 2,
//         "Protein per 100g": 3,
//         "Sodium per 100g": 583,
//         "Sugars_(Mono&Di-saccharides)_per 100g": 5,
//         "Trans_Fatty_Acids_per 100g": 0
//     },
//     {
//         "GRD": 127,
//         "Description": "Coconut Korma",
//         "BRAND_FLAG": "TASTY BITE",
//         "portionsize": 62,
//         "Regional product category": "Simmer sauce meal",
//         "H&N Framework": "meal maker",
//         "ENERGY KCAL": 161,
//         "Fruits Content per 100g": 0,
//         "Sugars Added_per_100g": 2,
//         "Vegetables_Content per 100g": 0,
//         "Wholegrains_Content per 100g": 0,
//         "Carbohydrate_per 100g": 10,
//         "Fat_Saturates_per 100g": 6,
//         "Fat_per 100g": 11,
//         "Fibre_Dietary_Total_AOAC per 100g": 2,
//         "Protein per 100g": 3,
//         "Sodium per 100g": 565,
//         "Sugars_(Mono&Di-saccharides)_per 100g": 3,
//         "Trans_Fatty_Acids_per 100g": 0
//     },
//     {
//         "GRD": 128,
//         "Description": "Spicy Vindaloo",
//         "BRAND_FLAG": "TASTY BITE",
//         "portionsize": 60,
//         "Regional product category": "Simmer sauce meal",
//         "H&N Framework": "meal maker",
//         "ENERGY KCAL": 117,
//         "Fruits Content per 100g": 0,
//         "Sugars Added_per_100g": 2,
//         "Vegetables_Content per 100g": 21,
//         "Wholegrains_Content per 100g": 0,
//         "Carbohydrate_per 100g": 10,
//         "Fat_Saturates_per 100g": 1,
//         "Fat_per 100g": 8,
//         "Fibre_Dietary_Total_AOAC per 100g": 2,
//         "Protein per 100g": 2,
//         "Sodium per 100g": 683,
//         "Sugars_(Mono&Di-saccharides)_per 100g": 5,
//         "Trans_Fatty_Acids_per 100g": 0
//     },
//     {
//         "GRD": 129,
//         "Description": "South-Indian Curry",
//         "BRAND_FLAG": "TASTY BITE",
//         "portionsize": 60,
//         "Regional product category": "Simmer sauce meal",
//         "H&N Framework": "meal maker",
//         "ENERGY KCAL": 200,
//         "Fruits Content per 100g": 0,
//         "Sugars Added_per_100g": 2,
//         "Vegetables_Content per 100g": 10,
//         "Wholegrains_Content per 100g": 0,
//         "Carbohydrate_per 100g": 15,
//         "Fat_Saturates_per 100g": 5,
//         "Fat_per 100g": 13,
//         "Fibre_Dietary_Total_AOAC per 100g": 3,
//         "Protein per 100g": 3,
//         "Sodium per 100g": 667,
//         "Sugars_(Mono&Di-saccharides)_per 100g": 7,
//         "Trans_Fatty_Acids_per 100g": 0
//     }
// ]
export default HomeData;