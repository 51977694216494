import React from "react";
import "./HoverCard.scss";

const HoverCard = ({ description, position, visible }) => {
  return (
    <div
      style={{
        position: "fixed", // Use 'fixed' to position relative to the viewport
        top: position.y + 20, // Offset by 20px below the word
        left: position.x, // Align left with the word
        padding: "16px",
        border: "1px solid #ccc",
        borderRadius:'4px',
        backgroundColor: "white",
        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
        zIndex: 9999,
        opacity: visible ? 1 : 0, // Control visibility
        transform: visible ? "translateY(0)" : "translateY(20px)", // Animate from below
        transition: "opacity 0.3s ease, transform 0.3s ease", // Smooth transition
        transitionDelay: "0.3s", // Delay the appearance
      }}
      className="hover-card-cont"
    >
      <p className="hover-text">{description}</p>
    </div>
  );
};

export default HoverCard;
