import React, { useState, useCallback } from "react";
import HoverHandler from "./HoverHandler";

const HighlightText = React.memo(({ summary }) => {
  const text = summary;

  const highlightedWords = {
    "prime cost":
      "A prime cost is the total direct costs of production, including raw materials and packaging materials",
    "cost center":
      "A cost centre is a department within a business to which costs can be allocated",
    "profit center":
      "A profit center is a department or division of a company that generates revenue and profits, and is treated as a separate business unit",
    "prime margin": "Affiliate Sales + GSV Third Party - Trade-Prime Cost",
    "confidence interval":
      "A 95% confidence interval for the standard normal distribution",
  };


  const renderText = useCallback(
    ({ handleMouseEnter, handleMouseLeave }) => {
      const lowerCaseText = text.toLowerCase(); // Convert the whole text to lowercase
      const words = Object.keys(highlightedWords); // Get all the words/phrases to highlight
      let currentIndex = 0;
      const elements = [];

      while (currentIndex < text.length) {
        // Look for the first match of any highlighted phrase starting from currentIndex
        const match = words
          .map((word) => ({
            word,
            index: lowerCaseText.indexOf(word, currentIndex), // Case-insensitive search
          }))
          .filter((match) => match.index !== -1)
          .sort((a, b) => a.index - b.index)[0]; // Get the first matching phrase

        if (!match) {
          // No more highlighted words, render the rest as normal text
          elements.push(
            <span key={currentIndex}>{text.slice(currentIndex)}</span>
          );
          break;
        }

        // Add the text before the match as a normal span
        if (currentIndex < match.index) {
          elements.push(
            <span key={currentIndex}>
              {text.slice(currentIndex, match.index)}
            </span>
          );
        }

        // Highlight the matched phrase with the original casing from `text`
        const originalMatchedText = text.slice(
          match.index,
          match.index + match.word.length
        );

        elements.push(
          <span
            key={match.index}
            style={{
              color: "#bb1ccc",
              textDecoration: "underline",
              cursor: "pointer",
              marginRight: "5px",
            }}
            onMouseEnter={(event) => handleMouseEnter(event, match.word)}
            onMouseLeave={handleMouseLeave}
          >
            {originalMatchedText}
          </span>
        );

        // Update currentIndex to move past the matched phrase
        currentIndex = match.index + match.word.length;
      }

      return elements;
    },
    [highlightedWords]
  );

  return (
    <HoverHandler highlightedWords={highlightedWords}>
      {({ handleMouseEnter, handleMouseLeave }) => (
        <div style={{ position: "relative" }}>
          <p>{renderText({ handleMouseEnter, handleMouseLeave })}</p>
        </div>
      )}
    </HoverHandler>
  );
});

export default HighlightText;
