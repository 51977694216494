import React, { useState, useRef, useCallback, useEffect } from "react";
import HoverCard from "./HoverCard";

const HoverHandler = React.memo(({ highlightedWords, children }) => {
  const [hovered, setHovered] = useState(false); // To track hover state
  const [hoveredWord, setHoveredWord] = useState(null); // Word being hovered
  const [position, setPosition] = useState({ x: 0, y: 0 }); // Position of the HoverCard
  const [hoverDelay, setHoverDelay] = useState(false); // To handle delayed disappearance

  // Function to handle mouse enter with delay
  const handleMouseEnter = useCallback(
    (event, word) => {
      const rect = event.target.getBoundingClientRect(); // Get position relative to the viewport
      setPosition({ x: rect.left, y: rect.bottom }); // Set hover card position
      setHoveredWord(highlightedWords[word.toLowerCase()]); // Set hovered word (use lowercase)
      setHovered(true); // Show hover card
      setHoverDelay(true); // Enable hover delay
    },
    [highlightedWords]
  );

  // Function to handle mouse leave with delay
  const handleMouseLeave = useCallback(() => {
    setHovered(false); // Hide hover card after delay
    setHoverDelay(false); // Disable hover delay
  }, []);

  return (
    <div>
      {children({ handleMouseEnter, handleMouseLeave })}

      {/* Conditionally render the HoverCard with animation */}
      {hovered && hoveredWord && (
        <HoverCard
          description={hoveredWord}
          position={position}
          visible={hoverDelay}
        />
      )}
    </div>
  );
});

export default HoverHandler;
