import React, { useCallback, useEffect, useRef, useState } from "react";
import MainLayout from "../../components/layout/MainLayout";
import Box from "@mui/material/Box";
import SendIcon from "@mui/icons-material/Send";
import AddIcon from "@mui/icons-material/Add";
import { styled } from "@mui/material/styles";
import $ from "jquery";
import Button from "@mui/material/Button";
import "./Message.scss";
import "./Home.scss";
import axios from "axios";
import DefaultLayout from "../../components/layout/DefaultLayout";
import MessageScreen from "../../components/layout/MessageScreen";
import HistoryLoadingPage from "../../components/layout/HistoryLoadingPage";
import { useHistory, useLocation } from "react-router-dom";
import ChatDisplay from "../../components/response/ChatDisplay";

import { useMediaQuery } from "react-responsive";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(2, 0),
  ...theme.mixins.toolbar,
}));

const Home = () => {
  const [userInput, setUserInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingHistory, setLoadingHistory] = useState(false);
  const [AskQuestion, setAskQuestion] = useState("");
  const [errorData, setErrorData] = useState(false);
  const [isHistory, setisHistory] = useState(false);
  const [SessionStart, setSessionStart] = useState(true);
  const [isDefaultPage, setisDefaultPage] = useState(true);
  const [responceData, setResponceData] = useState([]);
  const [GeneratingResponce, setGeneratingResponce] = useState(false);
  const [saveHistory, setSaveHistory] = useState(false);
  const chatWindowRef = useRef(null);
  const [Historyloading, setHistoryloading] = useState(false);
  const [SuccessHistoryloading, setSuccessHistoryloading] = useState(false);
  const [messageIndex, setMessageIndex] = useState(0);
  const componentRef = useRef(null);
  const componentRef1 = useRef([]);
  const [viewMore, setViewMore] = useState(false);
  const [responseNum, setResponseNum] = useState(null);
  const [HistoySections, setHistoySections] = useState(true);
  const [historyFlag, setHistoryFlag] = useState(1);
  const [messageId, setMessageId] = useState(0);
  const [faqScreen, setFaqScreen] = useState(true);
  const [query, setQuery] = useState("");
  const [loadingIndex, setLoadingIndex] = useState(0);
  const [GenerateResponceID, setGenerateResponceID] = useState("");
  const inputRef = useRef(null);
  const messageListRef = useRef(null);
  const [divNumber, setDivNumber] = useState(0);
  const history = useHistory();
  const location = useLocation();
  const [historyResponse, setHistoryResponse] = useState([]);
  const [historyDataFlag, setHistoryDataFlag] = useState(false);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const baseurl = `${process.env.REACT_APP_PYTHON_BACKEND_URL}/ask`;

  const isTab = useMediaQuery({ query: "(max-width: 768px)" });

  const handleSendClick = async () => {
    if (userInput === "") return false;
    setErrorData(false);
    setMessageId((prevState) => prevState + 1);

    setViewMore(true);

    setIsLoading(true);
    setFaqScreen(false);
    setQuery(userInput);

    setDivNumber((prevState) => prevState + 2);

    setResponceData((prevState) => [
      ...prevState,
      {
        query: userInput,
        user: true,
        loading: true,
        message_id: "",
        summary_of_summaries: "",
        content: "",
      },
    ]);
    if (responceData.length > 2) {
      setLoadingIndex((prevState) => prevState + 2);
    }

    const dataItem = { query: userInput };

    const source = axios.CancelToken.source();
    setCancelTokenSource(source);

    try {
      const response = await axios.post(baseurl, dataItem, {
        cancelToken: source.token,
      });
      const data = response.data;

      if (response.status === 200) {
        const botResponse = data.response;
        // if (botResponse?.content[0]?.status) {
        //   window.apiStatus = "success";
        // } else {
        //   window.apiStatus = "error";
        // }
        setResponceData((prevState) => [
          ...prevState,
          { ...botResponse, user: false },
        ]);
        setHistoryResponse((prevState) => [
          ...prevState,
          { response: botResponse },
        ]);
        setIsLoading(false);
        setTimeout(() => setSessionStart(false), 2000);
        setSaveHistory(true);
        setCancelTokenSource(null);
      } else {
        setIsLoading(false);
        setErrorData(true);
        setResponceData((prevState) => [
          ...prevState,
          { error: true, user: false },
        ]);
        // window.apiStatus = 'error';
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        console.log("Request canceled", err.message);
      } else {
        setIsLoading(false);
        setErrorData(true);
        setResponceData((prevState) => [
          ...prevState,
          { error: true, user: false },
        ]);
        console.log("Error occurred", err);
        // window.apiStatus = 'error';
      }
    }

    setUserInput("");
    setViewMore(false);
  };

  const handleCancelClick = () => {
    // if (cancelTokenSource !== null) {
    // }
    cancelTokenSource?.cancel("Request canceled by the user");
    setCancelTokenSource(null);
    console.log("Cancel token set to null");
  };

  useEffect(() => {
    return () => {
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Component unmounted");
      }
    };
  }, [cancelTokenSource]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (!isLoading) {
      handleSendClick();
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !isLoading) {
      handleFormSubmit(e);
    }
  };

  const handleExternalTextClick = (text) => {
    setUserInput(text);
    inputRef.current.focus();
  };

  const newChatStart = useCallback(() => {
    handleCancelClick();
    setisDefaultPage(false);
    setResponceData([]);
    setAskQuestion("");
    setisHistory(false);
    setHistoryloading(false);
    setSessionStart(true);
    setDivNumber(0);
    setResponseNum(0);
    setFaqScreen(true);
    setHistoryResponse([]);
    setUserInput("");
    setIsLoading(false);
    $(".bg-dark-card").removeClass("faqActive");
  }, []);

  useEffect(() => {
    if (divNumber > 2) {
      setTimeout(scrollToBottom, 200);
    }
  }, [divNumber]);

  useEffect(() => {
    if (isDefaultPage) {
      handleCancelClick();
    }
  }, [isDefaultPage]);

  const scrollToBottom = () => {
    let divId = "message-" + (divNumber - 2);
    let targetDiv = document.getElementById(divId);
    // console.log("Div Number: ", divId, targetDiv);

    if (targetDiv === null) {
      targetDiv = document.getElementById(divId);

      if (targetDiv) {
        targetDiv.scrollIntoView({
          behavior: "smooth", // Smooth scroll
          block: "start", // Center the div vertically in the viewport
        });
      }
    } else {
      if (targetDiv) {
        targetDiv.scrollIntoView({
          behavior: "smooth", // Smooth scroll
          block: "start", // Center the div vertically in the viewport
        });
      }
    }
  };

  // useEffect(() => {
  //   handlePrint();
  // }, [printIndex]);

  const fetchHistoryDataItem = useCallback(async (responseID, summary, Id) => {
    // const baseUrl = `${process.env.REACT_APP_BASE_URL}/aadi/history2.0/data2.0/response/${responseID}`;
    // setHistoryloading(true);
    // setViewMore(false);
    // try {
    //   $(".faqActive").removeClass("faqActive");
    //   $(`#faqsId_${Id}`).addClass("faqActive");
    //   setisHistory(true);
    //   setisDefaultPage(false);

    //   const response = await axios.get(baseUrl);
    //   // Handle the response data as needed
    //   if (response.status === 200) {
    //     // console.log('Response Data: ',response.data);
    //     setResponceData(response.data);
    //     setHistoryloading(false);
    //     setFaqScreen(false);
    //   }
    //   return response.data;
    // } catch (error) {
    //   // Handle the error as needed
    //   console.error("Error fetching history data:", error);
    //   throw error;
    // }

    history.push(`/history?id=${responseID}`);
  }, []);

  useEffect(() => {
    let searchParams = new URLSearchParams(location.search);

    let paramsObject = {};
    if (Array.from(searchParams).length > 0) {
      for (let [key, value] of searchParams.entries()) {
        paramsObject[key] = value;
      }
      if (paramsObject.historyQuery) {
        history.replace({
          pathname: location.pathname,
          search: "",
        });
        setisDefaultPage(false);
        setUserInput(paramsObject.historyQuery);
        localStorage.setItem("activeItem", null);
      } else {
        localStorage.setItem("activeItem", paramsObject?.responseId);
        history.replace({
          pathname: location.pathname,
          search: "",
        });
        fetchHistoryDataItem(
          paramsObject.responseId,
          paramsObject.summary,
          paramsObject.chatId
        );
      }
    } else {
      localStorage.setItem("activeItem", null);
    }
  }, []);

  const HistorySave = async () => {
    const baseUrl = `${process.env.REACT_APP_BASE_URL}//aadi/history2.0/data2.0`;
    // const baseUrl = ` http://127.0.0.1:5000/aadi/history2.0/data2.0`;

    const HistoryDataSave = [
      {
        responseID: GenerateResponceID || "",
        session: SessionStart,
        email: localStorage.getItem("userinfo"),
        data: historyResponse,
        from_cache: true
      },
    ];

    try {
      const response = await axios.post(baseUrl, HistoryDataSave);
      const data = response.data;
      setGenerateResponceID(data.response_id);
      if (response.status === 200) {
        setTimeout(() => {
          setSuccessHistoryloading(false);
        }, 2000);

        setHistoryDataFlag(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (saveHistory === true) {
      HistorySave();
      setTimeout(() => {
        setSaveHistory(false);
      }, 1000);
    }
  }, [saveHistory]);

  const appHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty("--app-height", `${window.innerHeight}px`);
  };
  window.addEventListener("resize", appHeight);
  useEffect(() => {
    appHeight();
  }, []);

  return (
    <div
      id="homePage"
      style={{ overflow: "hidden", height: "var(--app-height)" }}
      className={`${isDefaultPage ? "default-home-page" : ""}`}
    >
      <Box className="mainLayout" sx={{ display: "flex", overflow: "hidden" }}>
        <MainLayout
          // setHistoySections={setHistoySections}
          // HistoySections={HistoySections}
          setisDefaultPage={setisDefaultPage}
          // nodataImage={nodataImage}
          // isLoading={SuccessHistoryloading}
          fetchHistoryDataItem={fetchHistoryDataItem}
          setSuccessHistoryloading={setSuccessHistoryloading}
          newChatStart={newChatStart}
          historyFlag={historyFlag}
          setHistoryFlag={setHistoryFlag}
          isDefaultPage={isDefaultPage}
          page="Home"
          historyDataFlag={historyDataFlag}
          setHistoryDataFlag={setHistoryDataFlag}
        />

        <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
          <DrawerHeader />

          <div
            id="mainHome"
            className={`${isDefaultPage ? "default-setting" : ""}`}
          >
            <div
              id={
                HistoySections ? "mainChatContent_new" : "mainChatContent_new"
              }
              style={{ width: "100%" }}
              className={`${isDefaultPage ? "default-page" : ""}`}
            >
              {isDefaultPage ? (
                <>
                  <DefaultLayout newChatStart={newChatStart} />
                </>
              ) : (
                <>
                  {Historyloading ? (
                    <>
                      <HistoryLoadingPage />
                    </>
                  ) : (
                    <div ref={chatWindowRef} id="statistic1">
                      <div
                        className={isHistory ? "fullHeight" : ""}
                        id="statistic"
                        ref={messageListRef}
                      >
                        {!faqScreen && (
                          <ChatDisplay
                            responceData={responceData}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                            query={query}
                            errorData={errorData}
                            loadingIndex={loadingIndex}
                            setResponceData={setResponceData}
                            isHistory={isHistory}
                            page="Home"
                          />
                        )}
                        {faqScreen &&
                          responceData &&
                          responceData.length === 0 && (
                            <>
                              <MessageScreen
                                handleExternalTextClick={
                                  handleExternalTextClick
                                }
                                setUserInput={setUserInput}
                              />
                            </>
                          )}
                      </div>
                      <div className="col-12 inputTextboxHome">
                        <div
                          style={{
                            // display: "grid",
                            justifyContent: "flex-start",
                            justifyItems: "flex-start",
                            width: "180px",
                          }}
                        >
                          {isTab ? (
                            <Button
                              variant="contained"
                              color="primary"
                              // style={{
                              //   background: "#bb1ccc",
                              //   color: "#ffffff",
                              //   border: "none",
                              //   boxShadow: "none",
                              //   height: "37px",
                              // }}
                              className="submit_btn"
                              onClick={newChatStart}
                              style={{
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <AddIcon
                                style={{ height: "120%", width: "100%" }}
                              />
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              color="primary"
                              startIcon={<AddIcon />}
                              // style={{
                              //   background: "#bb1ccc",
                              //   color: "#ffffff",
                              //   border: "none",
                              //   boxShadow: "none",
                              //   height: "37px",
                              // }}
                              className="submit_btn"
                              onClick={newChatStart}
                            >
                              New Chat
                            </Button>
                          )}
                        </div>
                        <form onSubmit={handleFormSubmit} className="chat-form">
                          <div className="input-group mt-0 mb-0">
                            <input
                              ref={inputRef}
                              id="chat_pdf_input"
                              placeholder="Ask a question ..."
                              className="message-type-box form-control border-0"
                              type="text"
                              autoComplete="off"
                              value={userInput}
                              onChange={(e) => setUserInput(e.target.value)}
                              onKeyDown={handleKeyPress}
                            />
                            <Button
                              id="send_pdf_chat"
                              className="btn btn-light-success text-success sendbtn borderColorNew"
                              onClick={handleSendClick}
                              disabled={isLoading}
                              type="submit"
                            >
                              {isLoading ? (
                                <>
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                </>
                              ) : (
                                <SendIcon
                                  style={{ color: "#bb1ccc" }}
                                  className="colorCss"
                                />
                              )}
                            </Button>
                          </div>
                        </form>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default Home;
